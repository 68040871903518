<template>
  <transition-group name="fadeInUp" tag="div" class="introduce">
    <div v-for="item in introduce" :key="item.id" class="item">
      <div class="name-box">
        <img :src="item.iconsrc" class="icon" alt="" />
        <span class="name hidden-xs">{{ item.title }}</span>
        <img :src="item.namesrc" class="name-icon visible-sm" alt="" />
      </div>
      <div class="content">{{ item.info }}</div>
    </div>
  </transition-group>
</template>
<script>
export default {
  props: {
    type: {
      type: Number
    }
  },
  data() {
    return {
      solutionIntroduce: [
        {
          id: '1',
          iconsrc: require('@/assets/product/product_icon5.png'),
          namesrc: require('@/assets/product/name4.png'),
          title: '企事业单位员工福利',
          info: '心理学+算法+实名认证，让契合的“灵魂伴侣”有迹可寻，单身员工福利，高效靠谱的脱身神器。'
        },
        {
          id: '2',
          iconsrc: require('@/assets/product/product_icon6.png'),
          namesrc: require('@/assets/product/name5.png'),
          title: '交友类软件服务工具',
          info: '无须专门开发，告别单调普通的互动游戏，向有意向的男女提供该测试工具，节省了解时间，提高相处效率。'
        },
        {
          id: '3',
          iconsrc: require('@/assets/product/product_icon7.png'),
          namesrc: require('@/assets/product/name6.png'),
          title: '个人婚恋问题的辅助工具',
          info: '个人用户了解恋人、爱人，认识并解决婚恋问题的辅助性工具，适用于征婚者、恋人、即将步入婚姻的男女。'
        }
      ],
      productIntroduce: [
        {
          id: '1',
          iconsrc: require('@/assets/product/product_icon1.png'),
          namesrc: require('@/assets/product/name1.png'),
          title: '沉浸式剧情体验+心理测试',
          info: '将多维度性格测试，融入民国版趣味剧情，浙大、港大心理学教授把关，出具专属于你的性格分析报告+婚恋报告。'
        },
        {
          id: '2',
          iconsrc: require('@/assets/product/product_icon2.png'),
          namesrc: require('@/assets/product/name2.png'),
          title: '算法精准匹配',
          info: '获得测试报告后，系统以专业的算法逻辑，为你推荐三观一致、性格契合的异性进行匹配。大数据时代，节省3个月到1年的线下接触时间，快速触达对方真实内心。'
        },
        {
          id: '3',
          iconsrc: require('@/assets/product/product_icon3.png'),
          namesrc: require('@/assets/product/name3.png'),
          title: '线上约会、试婚',
          info: '邀请TA一起在小楼东约会，通过互动小游戏，测试双方爱情观、婚姻观、价值观，是否为契合的“灵魂伴侣”。'
        }
      ]
    };
  },
  computed: {
    introduce() {
      return this.type == 1 ? this.productIntroduce : this.solutionIntroduce;
    }
  }
};
</script>
<style lang="less" scoped>
.introduce {
  margin-top: 69px;
  .item {
    margin-bottom: 162px;
    text-align: center;
    .name-box {
      margin-bottom: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        width: 50px;
      }
      .name {
        margin-left: 18px;
        font-size: 32px;
        color: #333333;
        line-height: 68px;
      }
    }
    .content {
      font-size: 22px;
      color: #333333;
      line-height: 40px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
@media screen and (max-width: 1441px) {
  .introduce {
    .item {
      margin-bottom: 130px;
      .name-box {
        .name {
          font-size: 28px;
        }
        .icon {
          width: 40px;
        }
      }
      .content {
        font-size: 18px;
      }
    }
  }
}
@media screen and (max-width: 751px) {
  .introduce {
    margin-top: 0;
    padding: 0.4rem 0.2rem 0.28rem;
    .item {
      margin-bottom: 0.4rem;
      .name-box {
        margin-bottom: 0.13rem;
        justify-content: start;
        .icon {
          width: 0.22rem;
        }
        .name {
          margin-left: 0.1rem;
          font-size: 0.22rem;
          color: #57bbe5;
          line-height: 0.22rem;
        }
        .name-icon {
          margin-left: 0.1rem;
          height: 0.2rem;
        }
      }
      .content {
        font-size: 0.14rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.24rem;
        text-align: justify;
      }
      &:nth-child(1) {
        margin-bottom: 0.8rem;
        padding-right: 38%;
      }
      &:nth-child(2) {
        margin-bottom: 1.1rem;
        padding-right: 38%;
        .name-box {
          .name {
            color: #ffa6be;
          }
        }
      }
      &:nth-child(3) {
        padding-left: 38%;
      }
    }
  }
}
@media screen and (max-width: 751px) and (max-height: 690px) {
  .introduce {
    .item {
      &:nth-child(3) {
        margin-top: 0;
      }
    }
  }
}
@media screen and (max-width: 751px) and (max-height: 606px) {
  .introduce {
    .item {
      &:nth-child(1) {
        padding-right: 1.5rem;
      }
      &:nth-child(2) {
        padding-right: 1.5rem;
      }
      &:nth-child(3) {
        padding-left: 1.5rem;
      }
      &:nth-child(4) {
        padding-left: 1.5rem;
      }
    }
  }
}
</style>
