const male = {
  GRAND_THEATER: {
    major: 'GRAND_THEATER',
    position: 'bottom',
    list: [
      { content: '欢迎来到小楼东的世界。我等你好久啦。', isHouse: false },
      { content: '【金城大戏院】就在这里。已经开始检票了，我们赶紧过去吧。', isHouse: true }
    ],
    isPair: false
  },
  COFFEE_HOUSE: {
    major: 'COFFEE_HOUSE',
    position: 'bottom',
    list: [
      { content: '茫茫人海，知己难寻。你是否也是如此呢？别着急，我们先去【英林咖啡馆】坐一坐。听听音乐，小啜一口香醇的咖啡，让桃姐跟你聊几句。', isHouse: false },
      { content: '闻到咖啡豆的香味了吗，往这边走。', isHouse: false },
      { content: '无数浪漫轶事发生在英林咖啡馆，来这里喝杯咖啡，静静心吧。', isHouse: true }
    ],
    isPair: false
  },
  PUBLIC_SQUARE: {
    major: 'PUBLIC_SQUARE',
    position: 'top',
    list: [
      { content: '【民园广场】历来是个认识更多朋友的好去处，很多年轻人常在此聚集，也常举办各类活动。', isHouse: true },
      { content: '许多人在这里留下了各种各样的声音，深情款款的书信。', isHouse: true },
      { content: '更有不少人在这找到了适合自己的伴侣哦，一起去看看吧～', isHouse: true }
    ],
    isPair: false
  },
  RESTAURANTS: {
    major: 'RESTAURANTS',
    position: 'bottom',
    list: [
      { content: '太好了，你终于找到心仪的另一半了。想对她有更多了解，就需要多接触。', isHouse: false },
      { content: '想要多接触可以试着约她共享美食，在我们这儿，说到美食就逃不开【同福饭店】这个招牌了。', isHouse: true },
      { content: '一起享受美食也许能更快的增进双方的了解哦，快去试试吧！', isHouse: true }
    ],
    isPair: true
  },
  MOVIE_THEATER: {
    major: 'MOVIE_THEATER',
    position: 'top',
    list: [
      { content: 'userName，又和你见面了，你们在饭店发生的事儿，我老涂也有所耳闻了，可真是有趣啊。', isHouse: false },
      { content: '啥？接下来你不知道去哪儿？', isHouse: false },
      { content: '正好，最近有些新片上映了，你们要不一起去看看？咱们这儿【环球电影院】的格调，还是挺适合约会的。', isHouse: true }
    ],
    isPair: true
  },
  DEPARTMENT_STORE: {
    major: 'DEPARTMENT_STORE',
    position: 'bottom',
    list: [
      { content: 'userName，你们的亲密值好像有些低了呢。', isHouse: false },
      { content: '可以尝试送她一份礼物看看，不仅可以增加亲密值，而且说不定可以增进你们的感情哦！', isHouse: false },
      { content: '去【南方大厦百货】看看吧，里面琳琅满目的，一定有她喜欢的。', isHouse: true }
    ],
    isPair: true
  },
  PUBLIC_GARDEN: {
    major: 'PUBLIC_GARDEN',
    position: 'bottom',
    list: [{ content: '约会进程已过半，也许你们还需要加深一下互相的了解，可以去【贝当公园】散散步谈谈心哦！', isHouse: true }],
    isPair: true
  },
  TEA_HOUSE: {
    major: 'TEA_HOUSE',
    position: 'bottom',
    list: [
      { content: '老涂我可是听说，在【湖心亭茶楼】里有些测试能让你们更深入了解彼此！', isHouse: true },
      { content: '这些测试也许能在各个维度上反应出你们是否合适哦～', isHouse: true }
    ],
    isPair: true
  },
  TRAVEL_AGENCY: {
    major: 'TRAVEL_AGENCY',
    position: 'top',
    list: [
      { content: 'userName，都说在结婚同居前，最好能和她有一次旅行，你们是否也到这一步了呢！', isHouse: false },
      { content: '前面就是【光甫旅行社】了，试着在旅行过程中了解一下对方是否是能和你一起携手并肩走下去的人吧。', isHouse: true }
    ],
    isPair: true
  },
  DIVINATION_HOUSE: {
    major: 'DIVINATION_HOUSE',
    position: 'bottom',
    list: [
      { content: 'userName，到现在你是不是更想要了解和看到对方的一些信息呢！', isHouse: false },
      { content: '可以去到那边的【问心处】找女巫问问哦，也许她能给你解答。', isHouse: true }
    ],
    isPair: true
  },
  WEDDING_HALL: {
    major: 'WEDDING_HALL',
    position: 'bottom',
    list: [
      { content: '终于走到这一步啦，userName，一路走来一定不容易吧。', isHouse: false },
      { content: '但在恋爱中，我们常常会忽略对方的一些缺点，而生活中，这些缺点往往会被放大。', isHouse: false },
      { content: '不如去【礼堂】坦诚相对，回答一些更实际和生活化的问题，来检验一下她是否是那个能和你走到最后的人吧', isHouse: true }
    ],
    isPair: true
  },
  FAMILY_HOUSE: {
    major: 'FAMILY_HOUSE',
    position: 'top',
    list: [
      { content: '恭喜你们拥有自己的温馨小家——得一楼。', isHouse: true },
      { content: '愿得一人心，白首不分离！一屋、两人、三餐、四季……', isHouse: true },
      { content: '赶紧来装扮你们的新家吧！', isHouse: true }
    ],
    isPair: true
  },
  DIVINATION_HOUSE_TWO: {
    major: 'DIVINATION_HOUSE',
    position: 'top',
    list: [{ content: '问心处已经好久没去了，女巫那边好像又占卜出了新的信息，一起去看看吧。', isHouse: true }],
    isPair: true
  },
  LOVE_STAGE_PERORATION: {
    major: 'LOVE_STAGE_PERORATION',
    position: 'top',
    list: [
      { content: '“恋人关系的意义，大于在同一个屋檐下生活，大于共同生儿育女，你们应是彼此最重要的朋友，最亲密的人。”', isHouse: false },
      { content: '经过前面一系列的接触，相信你们对双方的性格特征，在日常生活中的习惯和选择，在爱和婚姻中的需求与表现，已有了一定程度的了解。', isHouse: false },
      { content: '不论合适与否，都感谢这一段难得的旅程。', isHouse: false },
      { content: '希望你们能坚持追寻理想之爱，相信好的感情对灵魂的滋养，保持热望，直到遇到属于你的灵魂伴侣。', isHouse: false }
    ],
    isPair: true
  }
};
const female = {
  COFFEE_HOUSE: {
    major: 'COFFEE_HOUSE',
    position: 'bottom',
    list: [
      { content: '茫茫人海，知己难寻。你是否也是如此呢？别着急，我们先去【英林咖啡馆】坐一坐。听听音乐，小啜一口香醇的咖啡，让桃姐跟你聊几句。', isHouse: false },
      { content: '闻到咖啡豆的香味了吗，往这边走。', isHouse: false },
      { content: '无数浪漫轶事发生在英林咖啡馆，来这里喝杯咖啡，静静心吧。', isHouse: true }
    ],
    isPair: false
  },
  GRAND_THEATER: {
    major: 'GRAND_THEATER',
    position: 'bottom',
    list: [
      { content: '欢迎来到小楼东的世界。我等你好久啦。', isHouse: false },
      { content: '【金城大戏院】就在这里。已经开始检票了，我们赶紧过去吧。', isHouse: true }
    ],
    isPair: false
  },
  PUBLIC_SQUARE: {
    major: 'PUBLIC_SQUARE',
    position: 'top',
    list: [
      { content: '【民园广场】历来是个认识更多朋友的好去处，很多年轻人常在此聚集，也常举办各类活动。', isHouse: true },
      { content: '许多人在这里留下了各种各样的声音，深情款款的书信……', isHouse: true },
      { content: '更有不少人在这找到了适合自己的伴侣哦，一起去看看吧～', isHouse: true }
    ],
    isPair: false
  },
  RESTAURANTS: {
    major: 'RESTAURANTS',
    position: 'bottom',
    list: [
      { content: '太好了，你终于找到心仪的他了。如果想对他有更多了解，就需要多接触。', isHouse: false },
      { content: '你可以试着约他共享美食，在我们这儿，说到美食就逃不开【同福饭店】这个招牌了。', isHouse: true },
      { content: '共享美食作为第一次接触，也许能更快的增进双方的了解哦，去试试吧！', isHouse: true }
    ],
    isPair: true
  },
  MOVIE_THEATER: {
    major: 'MOVIE_THEATER',
    position: 'top',
    list: [
      { content: 'userName，我们又见面了，你们在饭店发生的事我听说了，可真有趣呀！', isHouse: false },
      { content: '你问我接下来该去哪儿？', isHouse: false },
      { content: '正好，最近有些新片上映了，你要不和他一起去看看？咱们这儿【环球电影院】的格调，还是挺适合约会的。', isHouse: true }
    ],
    isPair: true
  },
  DEPARTMENT_STORE: {
    major: 'DEPARTMENT_STORE',
    position: 'bottom',
    list: [
      { content: 'userName，你们的亲密值好像有些不是很高呢。', isHouse: false },
      { content: '可以尝试送他一份礼物看看，不仅可以增加亲密值，而且说不定可以增进你们的感情哦！', isHouse: false },
      { content: '去【南方大厦百货】看看吧，里面琳琅满目的，一定有他喜欢的。', isHouse: true }
    ],
    isPair: true
  },
  PUBLIC_GARDEN: {
    major: 'PUBLIC_GARDEN',
    position: 'bottom',
    list: [
      { content: '约会进程已过半，还想要加深一下互相的了解吗？', isHouse: false },
      { content: '你们可以去【贝当公园】散散步谈谈心哦！', isHouse: true }
    ],
    isPair: true
  },
  TEA_HOUSE: {
    major: 'TEA_HOUSE',
    position: 'bottom',
    list: [
      { content: '春花听说，在【湖心亭茶楼】里有些测试能让你们更深入了解彼此！', isHouse: true },
      { content: '这些测试说不定能在各个维度上反应出你们是否合适哦～', isHouse: true }
    ],
    isPair: true
  },
  TRAVEL_AGENCY: {
    major: 'TRAVEL_AGENCY',
    position: 'top',
    list: [
      { content: 'userName，都说在结婚同居前，最好能和他有一次旅行，你们是否也到这一步了呢！', isHouse: false },
      { content: '前面就是【光甫旅行社】了，试着在旅行过程中了解一下对方是否是能和你一起携手并肩走下去的人吧', isHouse: true }
    ],
    isPair: true
  },
  DIVINATION_HOUSE: {
    major: 'DIVINATION_HOUSE',
    position: 'bottom',
    list: [
      { content: 'userName，到现在你是不是更想要了解和看到他的一些信息呢', isHouse: false },
      { content: '可以去到那边的【问心处】找女巫问问哦，也许她能给你解答', isHouse: true }
    ],
    isPair: true
  },
  WEDDING_HALL: {
    major: 'WEDDING_HALL',
    position: 'bottom',
    list: [
      { content: '终于走到这一步啦，userName，一路走来一定不容易吧', isHouse: false },
      { content: '但在恋爱中，我们常常会忽略对方的一些缺点，而生活中，这些缺点往往会被放大。', isHouse: false },
      { content: '不如去【礼堂】坦诚相对，回答一些更实际和生活化的问题，来检验一下他是否是那个能和你走到最后的人吧。', isHouse: true }
    ],
    isPair: true
  },
  FAMILY_HOUSE: {
    major: 'FAMILY_HOUSE',
    position: 'top',
    list: [
      { content: 'userName，恭喜你们拥有自己的温馨小家！', isHouse: true },
      { content: '得一楼 是你们这个小家的名字，愿得一人心，白首不分离哦！', isHouse: true },
      { content: '一屋、两人、三餐、四季……赶紧来装扮你们的新家吧！', isHouse: true }
    ],
    isPair: true
  },
  DIVINATION_HOUSE_TWO: {
    major: 'DIVINATION_HOUSE',
    position: 'top',
    list: [{ content: '问心处已经好久没去了，女巫那边好像又占卜出了新的信息，一起去看看吧。', isHouse: true }],
    isPair: true
  },
  LOVE_STAGE_PERORATION: {
    major: 'LOVE_STAGE_PERORATION',
    position: 'top',
    list: [
      { content: '“恋人关系的意义，大于在同一个屋檐下生活，大于共同生儿育女，你们应是彼此最重要的朋友，最亲密的人。”', isHouse: false },
      { content: '经过前面一系列的接触，相信你们对双方的性格特征，在日常生活中的习惯和选择，在爱和婚姻中的需求与表现，已有了一定程度的了解。', isHouse: false },
      { content: '不论合适与否，都感谢这一段难得的旅程。', isHouse: false },
      { content: '希望你们能坚持追寻理想之爱，相信好的感情对灵魂的滋养，保持热望，直到遇到属于你的灵魂伴侣。', isHouse: false }
    ],
    isPair: true
  }
};

const getGuideInfo = (major, sex) => {
  const list = sex == 1 ? male : female;
  return list[major];
};

export default getGuideInfo;
