/** 判断屏幕大小 */
export const isX = () => {
  // ，这里根据返回值 true 或false ,返回true的话 则为全面屏
  let result = false;
  const rate = window.screen.height / window.screen.width;
  const limit = window.screen.height == window.screen.availHeight ? 1.8 : 1.65; // 临界判断值

  if (rate > limit) {
    result = true;
  }
  return result;
};

// 验证手机号的格式
export const phoneValid = (phone) => {
  const reg = /^1[3456789]\d{9}$/;
  return reg.test(phone);
};
// 验证身份证的格式
export const idCardValid = (idCard) => {
  // const reg = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/;
  const reg = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/;
  return reg.test(idCard);
};

// 将base64转换为文件
export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

// 判断设备
// 是否是移动端
export const isMobile = () => {
  const u = navigator.userAgent;
  if (u.match(/Android|iPhone|iPad|iPod|iOS/i)) {
    return true;
  }
  return false;
};
// 是否是微信
export const isWx = () => {
  const u = navigator.userAgent;
  if (u.match(/MicroMessenger/i)) {
    return true;
  }
  return false;
};
// 是否是ios
export const isIos = () => {
  const u = navigator.userAgent;
  if (u.match(/iPhone|iPad|iPod|iOS/i)) {
    return true;
  }
  return false;
};
// 是否是安卓
export const isAndroid = () => {
  const u = navigator.userAgent;
  if (u.match(/Android/i)) {
    return true;
  }
  return false;
};

// 延迟等待
export const utilSleep = (delay) => {
  return new Promise((res) =>
    setTimeout(() => {
      res();
    }, delay)
  );
};
