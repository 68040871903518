<template>
  <div class="contactActive">
    <Header />
    <div class="contact-us">
      <div class="box-contact">
        <!-- 顶部 开始 -->
        <div class="box-top">
          <div class="layout-inner">
            <img src="@/assets/product/contact_us/word1.png" class="word1" alt="" />

            <img src="@/assets/product/contact_us/word2.png" class="word2" alt="" />
          </div>
        </div>
        <!-- 顶部 结束 -->
        <!-- 加入我们 开始 -->
        <div class="box-join layout-inner">
          <div class="content">
            <div class="title-en">JOIN US</div>
            <div class="title">加入我们</div>
            <div class="line"></div>
            <div class="p">
              <p>小楼东是一支年轻、时尚、头脑敏锐、朝气蓬勃的团队。他们充满活力、彰显个性，又愿意为远大的理想而付出脚踏实地的努力！</p>
              <p>如果您对自己的创造力、学习力充满信心，又相信“态度决定一切”的真理，愿付出辛勤的汗水去实现个人在专业领域内的职业化，这里将是你职业加速的优质跑道、梦想起飞的良好平台。</p>
              <p>为了你和小楼东共同的美好明天，让我们携手！</p>
            </div>
            <div class="stay">
              <div class="word">小楼东科技</div>
            </div>
          </div>
        </div>
        <!-- 加入我们 结束 -->
        <div class="box-word">
          <div class="hidden-xs">
            我们是一群有趣的人，做着<span>有趣</span>的事
          </div>
          <div class="visible-sm">
            <div>我们是一群有趣的人</div>
            <div>做着<span>有趣</span>的事</div>
          </div>
        </div>
        <div class="box-us layout-inner">
          <div class="us-item">
            <div class="title">
              <div class="content">
                <div class="icon"></div>
                我们有什么？
              </div>
            </div>
            <div class="us-list">
              <p>有钱、有甜、有梦！</p>
              <p>不要掩饰自己内心，咱不差钱，带你装X带你飞。</p>
            </div>
          </div>
          <div class="us-item">
            <div class="title">
              <div class="content">
                <div class="icon"></div>
                除了这还有啥？
              </div>
            </div>
            <div class="us-list">
              <div class="item" v-for="(item, index) in list" :key="index" :style="{ backgroundImage: 'url(' + item.icon + ')' }">{{ item.tip }}</div>
            </div>
          </div>
          <div class="us-item">
            <div class="title">
              <div class="content">
                <div class="icon"></div>
                我们要什么？
              </div>
            </div>
            <div class="us-list">
              <p>只要你能力好，还要三观正。</p>
              <p>要心怀梦想，更要踏踏实实。</p>
              <p>要独当一面，更要并肩作战。</p>
            </div>
          </div>
          <div class="us-item">
            <div class="title">
              <div class="content">
                <div class="icon"></div>
                COME ON~
              </div>
            </div>
            <div class="us-list">
              <p>如果你就是我们要找的那个人。</p>
              <p>那么，来吧！</p>
              <p>欢迎加入小楼东的大家庭！</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 简历投递 开始 -->
      <div class="resume-delivery">
        <div class="resume-left">
          <div class="box"><img src="@/assets/product/contact_us/line7.png" alt="" /> <img src="@/assets/product/contact_us/line8.png" alt="" /></div>
          <div class="center">
            <img src="@/assets/product/contact_us/word3.png" class="word" alt="" />
            <div class="word1">简历投递</div>
            <div class="word2">Join xiaoloudong</div>
            <img src="@/assets/product/contact_us/line11.png" class="line" alt="" />
            <div class="word3">每一个房子 —— 都有一个故事</div>
          </div>
          <div class="box"><img src="@/assets/product/contact_us/line9.png" alt="" /> <img src="@/assets/product/contact_us/line10.png" alt="" /></div>
        </div>
        <div class="resume-right">
          <div class="line line1"></div>
          <p>对流量运营和用户增长工作感兴趣，有自己的想法；</p>
          <p>热爱婚恋工作，拥有婚介服务的相关经验，最好1-3年的工作经验</p>
          <p>简历发送至HR邮: <span>service@xiaoloudong.com</span></p>
          <div class="line line2"></div>
          <div class="join">
            <div class="span">JOIN US</div>
          </div>
        </div>
      </div>
      <!-- 简历投递 结束 -->
      <!-- 联系我们 开始 -->
      <div class="box-contact-us">
        <div class="layout-inner">
          <div class="contact-left">
            <div class="title">联系我们</div>
            <div class="visible-sm visible-xs">
              <div class="map"></div>
              <div class="code-box">
                <div class="item">
                  <img src="@/assets/product/contact_us/code1.png" alt="" />
                  <div class="txt">微信<br />扫关注公众号</div>
                </div>
                <div class="item">
                  <img src="@/assets/product/contact_us/code2.png" alt="" />
                  <div class="txt">抖音<br />扫关注我们</div>
                </div>
                <div class="item">
                  <img src="@/assets/product/contact_us/code3.png" alt="" />
                  <div class="txt">微博<br />扫加入我们</div>
                </div>
              </div>
            </div>
            <ul class="list">
              <li class="item"><span class="name">公司名称：</span><span class="name-info">杭州小楼东科技有限公司</span></li>
              <li class="item"><span class="name">联系电话：</span><span class="name-info">17767141801</span></li>
              <li class="item"><span class="name">公司邮箱：</span><span class="name-info">service@xiaoloudong.com</span></li>
              <li class="item"><span class="name">公司地址：</span><span class="name-info">浙江省杭州市火炬高新科技园1号楼8层</span></li>
              <li class="item"><span class="name">用户反馈：</span><span class="name-info">user@xiaoloudong.com</span></li>
              <li class="item"><span class="name">商务合作：</span><span class="name-info">bd@xiaoloudong.com</span></li>
              <li class="item"><span class="name">媒体对接：</span><span class="name-info">pr@xiaoloudong.com</span></li>
            </ul>
          </div>
          <div class="contact-right hidden-xs hidden-sm">
            <div class="map"></div>
            <div class="code-box">
              <div class="item">
                <img src="@/assets/product/contact_us/code1.png" alt="" />
                <div class="txt">微信扫一扫关注公众号</div>
              </div>
              <div class="item">
                <img src="@/assets/product/contact_us/code2.png" alt="" />
                <div class="txt">抖音扫一扫关注我们</div>
              </div>
              <div class="item">
                <img src="@/assets/product/contact_us/code3.png" alt="" />
                <div class="txt">微博扫一扫加入我们</div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <!-- 联系我们 结束 -->
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/common/header';
import Footer from '@/components/common/footer';
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      list: [
        { icon: require('@/assets/product/contact_us/icon1.png'), tip: '五险一金、带薪休假。' },
        { icon: require('@/assets/product/contact_us/icon2.png'), tip: '项目奖 、年终奖。' },
        { icon: require('@/assets/product/contact_us/icon3.png'), tip: '年度公司outing、季度outing。' },
        { icon: require('@/assets/product/contact_us/icon4.png'), tip: '节日津贴、下午茶、生日会。' }
      ]
    };
  }
};
</script>
<style lang="less" scoped>
.contact-us {
  .layout-inner {
    margin: 0 auto;
    width: 1550px;
  }
  .clear {
    clear: both;
  }
  .box-contact {
    padding-bottom: 88px;
    background: #f7f4f0 url(~@/assets/product/about_us/bg2.png) no-repeat bottom center;
    background-size: contain;
    .box-top {
      padding: 200px 0 310px;
      background: #f7eee2 url(~@/assets/product/contact_us/bg.png) no-repeat bottom center;
      background-size: contain;
      img {
        margin: auto;
        display: block;
      }
      .word1 {
        max-width: 1170px;
      }
      .word2 {
        margin-top: 65px;
        max-width: 30%;
      }
    }
    .box-join {
      margin-top: -105px;
      height: 912px;
      background: #ffffff;
      border: 5px solid #06243c;
      .title-en {
        margin-top: 70px;
        font-size: 16px;
        color: #323232;
        text-align: center;
      }
      .title {
        margin-top: 20px;
        font-size: 48px;
        color: #323232;
        text-align: center;
      }
      .line {
        margin: 35px auto 80px;
        width: 60px;
        height: 5px;
        background: #ef1e21;
      }
      .p {
        margin: 0 auto;
        width: 67%;
        font-size: 30px;
        color: #5b5b5b;
        line-height: 50px;
      }
      .stay {
        margin-right: 11%;
        position: relative;
        &::after {
          content: 'JOIN';
          position: absolute;
          right: 0;
          top: 0;
          font-size: 170px;
          font-style: italic;
          font-weight: bold;
          color: #f1c6b8;
          line-height: 168px;
          height: 168px;
          opacity: 0.2;
        }
        .word {
          padding-right: 67px;
          position: relative;
          font-size: 30px;
          color: #323232;
          text-align: right;
          line-height: 168px;
          &::after {
            content: '';
            position: absolute;
            top: 84px;
            right: 235px;
            width: 60px;
            height: 3px;
            background: #323232;
          }
        }
      }
    }
    .box-word {
      margin: 117px auto 194px;
      padding-top: 14px;
      position: relative;
      width: 62.5%;
      font-size: 60px;
      color: #333333;
      line-height: 120px;
      text-align: center;
      &::after,
      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 94px;
        height: 94px;
      }
      &::after {
        left: 0;
        background: url(~@/assets/product/contact_us/line1.png) no-repeat center;
        background-size: contain;
      }
      &::before {
        right: 0;
        background: url(~@/assets/product/contact_us/line2.png) no-repeat center;
        background-size: contain;
      }
      span {
        color: #d26e6e;
      }
      img {
        width: 94px;
      }
    }
    .box-us {
      .us-item {
        margin-left: 50px;
        margin-bottom: 96px;
        width: 96%;
        background: #f8f5f1 no-repeat right bottom;
        background-size: 162px auto;
        border: 3px solid #323232;
        &:nth-child(1) {
          background-image: url(~@/assets/product/contact_us/line3.png);
        }
        &:nth-child(2) {
          background-image: url(~@/assets/product/contact_us/line4.png);
        }
        &:nth-child(3) {
          background-image: url(~@/assets/product/contact_us/line5.png);
          background-size: 154px auto;
        }
        &:last-child {
          margin-bottom: 0;
          background-image: url(~@/assets/product/contact_us/line6.png);
          background-size: 126px auto;
        }
        .title {
          margin-top: -40px;
          margin-left: -55px;
          position: relative;
          width: 490px;
          height: 111px;

          &::after {
            content: '';
            position: absolute;
            bottom: -10px;
            right: -10px;
            width: 100%;
            height: 90%;
            background: #ffffff;
            border: 3px solid #323232;
          }
          .content {
            padding-left: 27px;
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 2;
            font-size: 48px;
            color: #323232;
            background: #ffffff;
            border: 3px solid #323232;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .icon {
              margin-right: 45px;
              width: 63px;
              height: 63px;
              background: #e6c692;
              border: 3px solid #323232;
              border-radius: 50%;
            }
          }
        }
        .us-list {
          padding: 60px 0 58px 75px;
          font-size: 40px;
          font-weight: normal;
          color: #323232;
          p {
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .item {
            padding-left: 68px;
            margin-bottom: 40px;
            background: no-repeat left center;
            background-size: contain;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .resume-delivery {
    margin: 107px auto;
    width: 62.5%;
    height: 300px;
    background: #f9f5f0;
    border: 3px solid #000000;
    display: flex;
    justify-content: space-between;
    .resume-left {
      padding: 30px;
      width: 25%;
      background: #dae4e6;
      border-right: 3px solid #000000;
      .box {
        display: flex;
        justify-content: space-between;
        img {
          width: 26px;
        }
      }
      .center {
        text-align: center;
        .word {
          margin: 27px auto 6px;
          width: 77px;
          display: block;
        }
        .word1 {
          font-size: 30px;
          font-weight: normal;
          color: #333333;
          line-height: 32px;
        }
        .word2 {
          font-size: 12px;
          font-weight: normal;
          color: #333333;
          height: 10px;
          opacity: 0.6;
        }
        .line {
          margin: 15px auto;
          width: 11px;
          display: block;
          opacity: 0.3;
          transform: rotate(10deg);
        }
        .word3 {
          font-size: 12px;
          font-weight: normal;
          color: #333333;
          height: 14px;
          opacity: 0.6;
        }
      }
    }
    .resume-right {
      padding: 77px 40px 0;
      width: 75%;
      line-height: 37px;
      .line {
        width: 22px;
        height: 1px;
        background: #c8c8c8;
      }
      .line1 {
        margin-bottom: 20px;
      }
      .line2 {
        margin-top: 16px;
      }
      p {
        font-size: 24px;
        font-weight: normal;
        color: #333333;
        span {
          color: #e36464;
          font-weight: bold;
        }
      }
      .join {
        margin-top: 50px;
        position: relative;
        width: 142px;
        height: 44px;
        &::after {
          content: '';
          position: absolute;
          top: -4px;
          right: -4px;
          width: 100%;
          height: 100%;
          background: #000000;
          border-radius: 2px;
        }
        .span {
          position: relative;
          z-index: 1;
          width: 100%;
          height: 100%;
          background: #ffbaaa;
          border: 2px solid #000000;
          border-radius: 2px;
          font-size: 18px;
          font-weight: 400;
          line-height: 42px;
          text-align: center;
          font-style: italic;
          color: #ffffff;
        }
      }
    }
  }
  .box-contact-us {
    padding: 100px 0;
    background: #fafafa;
    .layout-inner {
      width: 1200px;
      .contact-left {
        width: 50%;
        float: left;
        .title {
          margin-bottom: 70px;
          font-size: 44px;
          font-weight: normal;
          color: #333333;
          line-height: 40px;
        }
        .list {
          .item {
            margin-bottom: 27px;
            font-size: 24px;
            font-weight: normal;
            color: #333333;
            line-height: 24px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .contact-right {
        width: 50%;
        float: right;
        .map {
          width: 100%;
          height: 255px;
          background: url(~@/assets/product/map.png) no-repeat center;
          background-size: cover;
        }
        .code-box {
          margin-top: 36px;
          display: flex;
          justify-content: space-between;
          .item {
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            line-height: 36px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1680px) {
  .contact-us {
    .layout-inner {
      width: 1200px;
    }
    .box-contact {
      .box-top {
        .word1 {
          max-width: 938px;
        }
        .word2 {
          margin-top: 55px;
          max-width: 25%;
        }
      }
      .box-join {
        margin-top: -105px;
        height: 665px;
        .title-en {
          margin-top: 56px;
        }
        .title {
          font-size: 38px;
        }
        .line {
          margin: 25px auto 64px;
        }
        .p {
          font-size: 20px;
          line-height: 34px;
        }
        .stay {
          &:after {
            font-size: 136px;
            line-height: 134px;
            height: 134px;
          }
          .word {
            &:after {
              top: 65px;
              right: 188px;
            }
            padding-right: 53px;
            line-height: 134px;
            font-size: 24px;
          }
        }
      }
      .box-word {
        &:after,
        &::before {
          width: 75px;
          height: 75px;
        }
        margin: 100px auto 160px;
        padding-top: 14px;
        line-height: 96px;
        font-size: 48px;
      }
      .box-us {
        .us-item {
          margin-bottom: 80px;
          .title {
            width: 470px;
            height: 90px;
            .content {
              font-size: 36px;
            }
          }
          .us-list {
            font-size: 28px;
          }
        }
      }
    }
    .resume-delivery {
      height: 270px;
      .resume-left {
        padding: 24px;
        .center {
          .word {
            margin: 21px auto 6px;
            width: 60px;
          }
          .word3 {
            margin-bottom: 6px;
          }
        }
      }
      .resume-right {
        padding: 60px 32px 0;
        p {
          font-size: 16px;
        }
        .join {
          margin-top: 40px;
          width: 113px;
        }
      }
    }
    .box-contact-us {
      .layout-inner {
        width: 960px;
        .contact-left {
          .title {
            margin-bottom: 50px;
            font-size: 34px;
          }
          .list {
            .item {
              font-size: 18px;
            }
          }
        }
        .contact-right {
          .code-box {
            margin-top: 26px;
            .item {
              font-size: 14px;
              line-height: 24px;
              img {
                width: 60%;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .visible-xs {
    display: none !important;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .hidden-sm {
    display: none !important;
  }
}
@media screen and (max-width: 991px) {
  .contact-us {
    .layout-inner {
      padding: 0 0.2rem;
      width: 100%;
    }
    .box-contact {
      padding-bottom: 0;
      .box-top {
        padding: 1.03rem 0 1.3rem;
        background: url(~@/assets/product/contact_us/bg.jpg) no-repeat bottom center;
        background-size: cover;
        .word1 {
          max-width: 100%;
          height: 0.24rem;
        }
        .word2 {
          margin-top: 0.3rem;
          max-width: 100%;
          height: 0.18rem;
        }
      }
      .box-join {
        margin: -0.4rem auto 0;
        padding: 0;
        position: relative;
        width: 92%;
        height: auto;
        background: transparent;
        border: 0;
        &::after {
          content: '';
          position: absolute;
          bottom: -0.1rem;
          width: 99%;
          height: 100%;
          background: #e6c692;
          border-radius: 0.08rem;
          border: 1px solid #333333;
        }
        .content {
          padding: 0 0.15rem 0.1rem;
          position: relative;
          z-index: 1;
          background: #ffffff;
          border-radius: 0.08rem;
          border: 1px solid #333333;
        }
        .title-en {
          margin-top: 0.2rem;
          font-size: 0.12rem;
          line-height: 0.14rem;
          transform: scale(0.8);
        }
        .title {
          margin-top: 0.05rem;
          font-weight: bold;
          font-size: 0.16rem;
          line-height: 0.2rem;
        }
        .line {
          margin: 0.1rem auto 0.15rem;
          width: 0.3rem;
          height: 0.02rem;
        }
        .p {
          width: 100%;
          font-size: 0.14rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.26rem;
        }
        .stay {
          margin-right: 0.1rem;
          height: 0.8rem;
          &::after {
            top: 0;
            right: 0.15rem;
            font-size: 0.8rem;
            height: 0.9rem;
            line-height: 0.8rem;
          }
          .word {
            padding-right: 0;
            font-size: 0.14rem;
            font-weight: 400;
            color: #333333;
            line-height: 0.8rem;
            &::after {
              top: 0.38rem;
              right: 0.8rem;
              width: 0.3rem;
              height: 0.01rem;
            }
          }
        }
      }
      .box-word {
        margin: 0.5rem auto 0.6rem;
        padding-top: 0.1rem;
        width: 75%;
        font-size: 0.22rem;
        font-weight: bold;
        line-height: 0.3rem;
        &::after,
        &:before {
          width: 0.35rem;
          height: 0.35rem;
        }
      }
      .box-us {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .us-item {
          margin-left: 0.1rem;
          margin-bottom: 0.45rem;
          width: 46%;
          height: 2rem;
          border: 1px solid #323232;
          background-size: 0.5rem auto;
          &:nth-child(1) {
            width: 42%;
            .title {
              width: 1.6rem;
            }
          }
          &:nth-child(3) {
            margin-bottom: 0.35rem;
            width: 42%;
            background-size: 0.5rem auto;
            .title {
              width: 1.6rem;
            }
          }
          &:nth-child(4) {
            margin-bottom: 0.35rem;
            background-size: 0.3rem auto;
          }
          .title {
            margin-top: -0.3rem;
            margin-left: -0.13rem;
            width: 1.8rem;
            height: 0.44rem;
            &::after {
              bottom: -0.05rem;
              right: -0.05rem;
              border: 1px solid #333333;
            }
            .content {
              padding-left: 0.05rem;
              font-size: 0.16rem;
              font-weight: bold;
              line-height: 0.17rem;
              border: 1px solid #333333;
              .icon {
                margin-right: 0.05rem;
                width: 0.28rem;
                height: 0.28rem;
                border: 1px solid #333333;
              }
            }
          }
          .us-list {
            padding: 0.15rem 0.01rem 0;
            font-size: 0.14rem;
            line-height: 0.24rem;
            p {
              margin-bottom: 0.08rem;
            }
            .item {
              padding-left: 0.2rem;
              margin-bottom: 0.08rem;
              background: no-repeat left top 0.03rem;
              background-size: 0.15rem auto;
            }
          }
        }
      }
    }
    .resume-delivery {
      margin: 0.4rem auto;
      width: 92%;
      height: 3.4rem;
      background: #f9f5f0;
      border: 1px solid #000000;
      display: block;
      .resume-left {
        padding: 0.15rem;
        width: 100%;
        border-right: 0;
        border-bottom: 1px solid #000000;
        .box {
          img {
            width: 0.15rem;
          }
        }
        .center {
          .word {
            margin: 0.05rem auto;
            width: 0.4rem;
          }
          .word1 {
            font-size: 0.16rem;
            color: #333333;
            font-weight: 500;
            line-height: 0.22rem;
          }
          .word2 {
            font-size: 0.12rem;
            font-weight: 400;
            color: #999999;
            line-height: 0.11rem;
            transform: scale(0.8);
          }
          .line {
            margin: 0.04rem auto;
            width: 0.06rem;
            opacity: 1;
          }
          .word3 {
            font-size: 0.12rem;
            transform: scale(0.9);
            height: 0.14rem;
          }
        }
      }
      .resume-right {
        width: 100%;
        padding: 0.2rem 0.15rem 0;
        line-height: 0.3rem;
        .line {
          width: 0.1rem;
        }
        .line1 {
          margin-bottom: 0.1rem;
        }
        .line2 {
          margin-bottom: 0.05rem;
        }
        p {
          font-size: 0.12rem;
        }
        .join {
          margin-top: 0.2rem;
          width: 0.75rem;
          height: 0.3rem;
          font-weight: bold;
          &::after {
            top: -0.02rem;
            right: -0.02rem;
            width: 100%;
            height: 100%;
            border-radius: 0;
          }
          .span {
            border: 1px solid #000000;
            border-radius: 0px;
            font-size: 0.12rem;
            line-height: 0.26rem;
          }
        }
      }
    }
    .box-contact-us {
      padding: 0.15rem 0 0.2rem;
      background: #fafafa;
      .layout-inner {
        width: 100%;
        .contact-left {
          width: 100%;
          float: none;
          .title {
            margin-bottom: 0.1rem;
            font-size: 0.2rem;
            font-weight: bold;
          }
          .list {
            margin-top: 0.2rem;
            .item {
              font-size: 0.14rem;
              margin-bottom: 0.1rem;
              line-height: 0.2rem;
            }
          }
          .map {
            width: 100%;
            height: 2.2rem;
            background: url(~@/assets/product/map.png) no-repeat center;
            background-size: cover;
          }
          .code-box {
            padding: 0 0.2rem;
            margin-top: 0.2rem;
            display: flex;
            justify-content: space-between;
            .item {
              text-align: center;
              img {
                width: 0.8rem;
              }
              .txt {
                font-size: 0.12rem;
                font-weight: 400;
                color: #333333;
                line-height: 0.2rem;
              }
            }
          }
        }
        .contact-right {
          display: none;
        }
      }
    }
  }
}
</style>
