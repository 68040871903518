<template>
  <transition name="fadeInUp">
    <div class="headline">
      <span>{{ title }}</span>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    title: {
      type: String
    }
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.headline {
  text-align: center;
  margin-top: 100px;
  font-size: 2.2em;
  color: #333333;
  line-height: 1.2em;
  span {
    position: relative;
    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 17px;
      height: 17px;
      background: #333333;
      border-radius: 50%;
    }
    &:after {
      right: -60px;
    }
    &:before {
      left: -60px;
    }
  }
}
@media screen and (max-width: 1661px) {
  .headline {
    margin-top: 80px;
    font-size: 2em;
    span {
      &:after,
      &:before {
        width: 12px;
        height: 12px;
      }
    }
  }
}
@media screen and (max-width: 751px) {
  .headline {
    margin-top: 0;
    font-size: 0.3rem;
    color: #333333;
    line-height: 0.28rem;
    span {
      &:after {
        display: none;
      }
      &:before {
        display: none;
      }
    }
  }
}
</style>
