import { getToken } from '@/utils/auth';
import { mapGetters } from 'vuex';
const mixin = {
  data() {
    return {
      selectHouse: {}
    };
  },
  computed: {
    ...mapGetters(['relevantInfo']),
    newList() {
      let list = this.houseList || [];
      list = list.map((item) => {
        return item;
      });
      return list;
    },
    sexClass() {
      return this.relevantInfo.sex == 1 ? 'male' : 'female';
    }
  },
  mounted() {},
  methods: {
    // 点击空白 清除所有选中状态
    onReset() {
      this.showCode = false;
      this.showPhoneLogin = false;
    },
    // 选择模块
    onSelectHouse() {
      const container = this.$refs.container;

      if (container && container.dataset.drag == 'true') {
        return false;
      }
      this.showRegister = true;
      if (!getToken()) {
        this.step = 1;
        return false;
      }
      if (this.relevantInfo.sex == 0) {
        this.step = 2;
        return false;
      }
      if (!this.relevantInfo.lableStatus) {
        this.step = 3;
        return false;
      }
      this.step = 4;
    },
    // 凸显房子
    prominentHouse(major) {
      this.houseList = this.houseList.map((item) => {
        if (major == item.major) {
          item.isProminent = true;
        } else {
          item.isProminent = false;
        }
        return item;
      });
    }
  }
};
export default mixin;
