<template>
  <div class="homeActive">
    <Header class="hidden-xs" />
    <div class="home">
      <div class="video-bg hidden-xs ">
        <video ref="video" poster="@/assets/video/1.jpg" src="http://xld-public.oss-cn-beijing.aliyuncs.com/prod/video/xiaoloudong1.MP4" muted="muted" loop="loop" :preload="ispreload" class="my-video hidden-xs"></video>
      </div>
      <div class="main-box hidden-xs ">
        <div class="left-phone left-phone2"></div>
        <img src="@/assets/home/phone.png" class="left-phone left-phone1" alt="" />
        <div class="right-info">
          <div class="tip-img"></div>
          <div class="txt">每天都有人在这里相遇，你的TA也在等你</div>
          <div class="btn-box ">
            <div class="btn btn-code" :class="nowcode ? 'active' : ''" @mouseenter="isNowcode" @mouseleave="nowcode = false">
              立即体验
              <div class="now-frame" v-if="nowcode">
                <img src="@/assets/home/qrcode1_1.jpg" class="code" alt="" />
              </div>
            </div>
            <!-- <div class="btn btn-code" :class="nowAndroid ? 'active' : ''" @mouseenter="isNowAndroid" @mouseleave="nowAndroid = false">
              Android
              <div class="now-frame" v-if="nowAndroid">
                <img src="@/assets/home/qrcode2.png" class="code" alt="" />
                <div style="padding-top:10px;">安卓包</div>
              </div>
            </div> -->
            <router-link class="btn btn-code" to="/goPlay" target="_blank">
              去玩一下
            </router-link>
            <!-- <div class="btn en btn-ios" :class="nowIos ? 'active' : ''" @mouseenter="isNowIos" @mouseleave="nowIos = false">
            <img class="icon" style="margin-top: -6px;" src="@/assets/home/icon_ios_1.png" alt="" />ios
            <div class="now-frame" style="padding-top:10px;" v-if="nowIos">开发中，敬请期待</div>
          </div> 
            <div class="btn en" :class="nowAndroid ? 'active' : ''" @mouseenter="isNowAndroid" @mouseleave="nowAndroid = false">
              <img class="icon" src="@/assets/home/qrcode2.png" alt="" />Android
              <div class="now-frame" style="padding-top:10px;" v-if="nowAndroid">开发中，敬请期待</div>
            </div>-->
          </div>
        </div>
      </div>
      <div class="home-box visible-sm">
        <Header />
        <img src="@/assets/home/mob_bg.jpg" class="bg" alt="" />
        <img src="@/assets/home/img_logo.png" class="img-logo" alt="" />
        <!-- <div class="page1">
          <img src="@/assets/common/icon_down.png" class="next" alt="" />
        </div> -->
      </div>
      <!-- <swiper ref="mySwiper" :options="swiperOption" class="visible-sm" :style="`${styleHeight}`">
        <swiper-slide>
          <Header />
          <img src="@/assets/home/mob_bg.png" class="bg" alt="" />
          <img src="@/assets/home/img_logo.png" class="img-logo" alt="" />
          <div class="page1">
            <img src="@/assets/common/icon_down.png" class="next" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="page2">
            <Introduce :type="1" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="page2">
            <Introduce :type="2" />
            <Footer />
          </div>
        </swiper-slide>
      </swiper> -->
    </div>
    <Footer class="hidden-xs" />
  </div>
</template>
<script>
import 'swiper/swiper-bundle.css';
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
// import Introduce from '@/components/product/introduce';
import Header from '@/components/common/header';
import Footer from '@/components/common/footer';
export default {
  components: {
    // Swiper,
    // SwiperSlide,
    Header,
    // Introduce,
    Footer
  },
  data() {
    return {
      nowcode: false,
      nowIos: false,
      nowAndroid: false,
      ispreload: 'none',
      styleHeight: '',
      swiperOption: {
        centeredSlides: true,
        noSwiping: true,
        direction: 'vertical'
      }
    };
  },
  created() {
    let clinetHeight = document.documentElement.clientHeight;
    let clientWidth = document.documentElement.clientWidth;
    this.styleHeight = 'height:' + clinetHeight + 'px;';
    this.$nextTick(() => {
      if (clientWidth > 752) {
        this.ispreload = 'auto';
        this.videoPlay();
      } else {
        this.ispreload = 'none';
        this.videoPause();
      }
    });
  },
  mounted() {
    console.log(document.documentElement.clientHeight, document.documentElement.clientWidth);
  },
  methods: {
    isNowcode() {
      this.nowcode = true;
    },
    isNowIos() {
      this.nowIos = true;
    },
    isNowAndroid() {
      this.nowAndroid = true;
    },
    videoPlay() {
      let video = this.$refs.video;
      video.play();
    },
    videoPause() {
      let video = this.$refs.video;
      video.pause();
    }
  }
};
</script>
<style lang="less" scoped>
.homeActive {
  position: relative;
  width: 100%;
  height: 100vh;
  // background: url(~@/assets/common/home_bg.jpg) no-repeat center center;
  // background-size: cover;
  .header {
    .head-box {
      position: fixed;
      top: 0;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    color: #fff;
  }
  @media screen and (max-width: 751px) {
    .footer {
      position: absolute;
    }
  }
}
.home {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  .video-bg {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: hidden;
    width: 100%;
    height: 100%;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
    }
    .my-video {
      min-width: 100%;
      min-height: 100%;
      object-fit: fill;
    }
  }
  .main-box {
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    // align-items: center;

    .left-phone2 {
      width: 300px;
      height: 600px;
      margin-right: 50px;
      background: url(~@/assets/home/phone.png) no-repeat left top;
      background-size: 100% 100%;
    }
    .left-phone1 {
      display: none;
    }
    .right-info {
      padding-top: 67px;
      width: 700px;
      margin-left: 1.5em;
      .tip-img {
        margin-bottom: 15px;
        width: 557px;
        height: 229px;
        background: url(~@/assets/home/word1.png) no-repeat left center;
        background-size: contain;
      }
      .txt {
        font-size: 36px;
        line-height: 1.2;
        color: #fff;
      }
      .codeimg {
        margin: 20px auto;
        padding: 10px;
        width: 155px;
        height: 155px;
        background: #ffffff;
        z-index: 20;
        border-radius: 15px;
        text-align: center;
        overflow: hidden;
        .code-txt {
          margin-bottom: 10px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
        }

        .code {
          max-width: 100%;
        }
      }
      .btn-box {
        margin-top: 50px;
        display: flex;
        justify-content: start;
        .btn {
          margin-right: 15px;
          position: relative;
          width: 157px;
          height: 43px;
          line-height: 45px;
          background: #ffffff;
          border-radius: 21px;
          font-size: 18px;
          color: #333333;
          text-align: center;
          font-weight: normal;
          cursor: pointer;
          img {
            margin-top: -5px;
          }
          &.active {
            border-radius: 21px 21px 0 0;
            .now-frame {
              display: block;
            }
          }
          .icon {
            margin-right: 10px;
            width: 24px;
            vertical-align: middle;
          }
        }
      }
    }

    .now-frame {
      padding-bottom: 10px;
      position: absolute;
      top: 100%;
      z-index: 20;
      width: 100%;
      height: auto;
      background: #fff;
      border-radius: 0 0 20px 20px;
      font-size: 17px;
      line-height: 18px;
      .code {
        width: 135px;
      }
    }
  }
}
@media screen and (max-width: 1681px) {
  .home {
    background: #000;
    .main-box {
      .left-phone {
        zoom: 0.9;
      }
      .right-info {
        zoom: 0.9;
        .txt {
          font-size: 2em;
        }
        .btn-box {
          margin-top: 50px;
        }
      }
    }
  }
}
@media screen and (max-width: 1661px) {
  .home {
    .main-box {
      .right-info {
        .txt {
          font-size: 1.8em;
        }
        .btn-box {
          margin-top: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 1367px) {
  .home {
    .main-box {
      .left-phone {
        margin-right: 0;
        zoom: 0.8;
      }
      .right-info {
        margin-left: 10%;
        zoom: 0.8;
        .tip-img {
          zoom: 0.8;
        }
        .txt {
          font-size: 1.6em;
        }
      }
    }
  }
}
@media screen and (min-height: 820px) and (max-height: 900px) {
  .home {
    .main-box {
      .left-phone {
        zoom: 0.9;
      }
      .right-info {
        .btn-box {
          .btn {
            zoom: 0.9;
          }
        }
        .txt {
          zoom: 0.9;
        }
      }
    }
  }
}
@media screen and (max-height: 820px) {
  .home {
    .main-box {
      zoom: 0.8;
      .left-phone2 {
        display: none;
      }
      .left-phone1 {
        display: block;
      }
      .left-phone {
        zoom: 0.8;
      }
      .right-info {
        zoom: 0.9;
      }
    }
  }
}
@media screen and (max-height: 720px) {
  .home {
    .main-box {
      zoom: 0.7;
    }
  }
}
@media screen and (max-height: 700px) {
  .home {
    .main-box {
      zoom: 0.7;
      .left-phone {
        zoom: 0.7;
      }
      .right-info {
        zoom: 0.7;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .home {
    .main-box {
      .right-info {
        margin-left: 4%;
        width: 98%;
      }
      .left-phone1 {
        display: block;
        max-width: 46%;
      }
    }
  }
}
@media screen and (max-width: 751px) {
  .home {
    position: relative;
    background: #fff;
    .video-bg {
      &:after {
        background: rgba(0, 0, 0, 0.4);
      }
    }
    .main-box {
      top: 4.68rem;
      .right-info {
        padding-top: 0;
        width: 6.54rem;
      }
    }
    .home-box {
      position: relative;
      overflow: hidden;
      z-index: 1;
      width: 100%;
      height: 100vh;
      .bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        object-fit: cover;
      }
      .img-logo {
        position: absolute;
        top: 1.2rem;
        left: 0.3rem;
        width: 2.25rem;
      }
      .swiper-slide {
        .page1 {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // background: rgba(0, 0, 0, 0.4);
          .tip-img {
            margin: auto;
            position: absolute;
            left: 0;
            right: 0;
            top: 40%;
            width: 3.27rem;
            height: 1.28rem;
            background: url(~@/assets/home/word1.png) no-repeat left center;
            background-size: contain;
          }
        }
        .page2 {
          width: 100%;
          height: 100%;
          background: url(~@/assets/video/swiper2.png) no-repeat center;
          background-size: 100% 100%;
        }
      }
    }
    .main-bottom {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0.72rem;
      .txt {
        margin-bottom: 0.2rem;
        font-size: 0.18rem;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.25rem;
        text-align: center;
      }
      .code-box {
        display: flex;
        justify-content: center;
        align-items: center;
        .code-img {
          margin-right: 0.2rem;
          width: 0.44rem;
        }
        .code-txt {
          font-size: 0.16rem;
          font-weight: 400;
          color: #cccccc;
          line-height: 0.22rem;
        }
      }
    }
    .next {
      display: block;
      margin: auto;
      width: 0.2rem;
      height: 0.1rem;
      margin-left: -0.1rem;
      left: 50%;
      position: absolute;
      bottom: 0.8rem;
      -webkit-animation: gonext 1.8s infinite linear;
      animation: gonext 1.8s infinite linear;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);
    }
  }
}

@keyframes gonext {
  0% {
    bottom: 0.8rem;
    opacity: 1;
  }
  60% {
    bottom: 0.5rem;
    opacity: 0;
  }
  100% {
    bottom: 0.8rem;
    opacity: 0;
  }
}
</style>
