<template>
  <div class="user-info">
    <div class="my" @click="showCodeDialog">
      <div class="head-box">
        <div class="head">
          <img class="img" :src="relevantInfo.headImgUrl" alt="" />
        </div>
      </div>
      <div class="name van-ellipsis">{{ relevantInfo.nickName }}</div>
    </div>
    <template v-if="relevantInfo.isPair == 1">
      <div class="target">
        <div tag="div" class="head-box" @click="showCodeDialog">
          <div class="head">
            <img class="img" :src="targetUserInfo.headImgUrl" alt="" />
            <div class="online" :class="{ ol: isOnline }"></div>
          </div>
          <div class="name van-ellipsis">{{ targetUserInfo.nickName }}</div>
        </div>
        <div class="intimacy-box" @click="showCodeDialog">
          <div class="progress-box">
            <div class="heart"></div>
            <div class="progress" :style="{ 'background-position': progress.intimacy + '%' }">{{ progress.intimacyValue }}</div>
          </div>
        </div>
      </div>
    </template>
    <div class="wealth-box">
      <div class="wealth">
        <div class="icon">
          <img src="@/assets/goplay/index/icon_wallet_gold.png" alt="" />
        </div>
        <div class="num">{{ relevantInfo.goldCoinBalance | numberMaxFilter(999999) }}</div>
        <div class="recharge" @click="showCodeDialog">
          <img src="@/assets/goplay/index/icon_add.png" alt="" />
        </div>
      </div>
      <div class="wealth">
        <div class="icon">
          <img src="@/assets/goplay/index/icon_wallet_diamond.png" alt="" />
        </div>
        <div class="num">{{ relevantInfo.diamondBalance | numberMaxFilter(999999) }}</div>
        <div class="recharge" @click="showCodeDialog">
          <img src="@/assets/goplay/index/icon_add.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from '@/utils/Bus';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      isOnline: false
    };
  },
  computed: {
    ...mapGetters(['relevantInfo', 'targetUserInfo']),
    progress() {
      const { userRelevantFamilyInfo } = this.relevantInfo;
      const { intimacyValue = 0, intimacyMaxValue = 1 } = userRelevantFamilyInfo || {};
      let intimacy = (intimacyValue / intimacyMaxValue) * 100;
      if (intimacy <= 0) {
        intimacy = 0;
      } else if (intimacy < 50) {
        intimacy = 25;
      } else if (intimacy < 80) {
        intimacy = 50;
      } else if (intimacy < 100) {
        intimacy = 75;
      } else if (intimacy >= 100) {
        intimacy = 100;
      }
      return {
        intimacy,
        intimacyValue,
        intimacyReal: (intimacyValue / intimacyMaxValue) * 100,
        intimacyText: `${intimacyValue} / ${intimacyMaxValue}`
      };
    }
  },
  watch: {
    'relevantInfo.isPair'() {
      this.getOnline();
    }
  },
  created() {
    Bus.$on('set-online', this.setOnline);
    this.getOnline();
  },
  beforeDestroy() {
    Bus.$off('set-online', this.setOnline);
  },
  methods: {
    // 在线转态
    getOnline() {
      if (this.relevantInfo.isPair == 1) {
        this.$http.get(`/user/info/isOnline/${this.targetUserInfo.userId}`).then((res) => {
          this.isOnline = res.data;
        });
      }
    },
    setOnline(state) {
      this.isOnline = state;
    },
    showCodeDialog() {
      this.$parent.onSelectHouse();
    }
  }
};
</script>

<style lang="less" scoped>
.user-info {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 0.88rem;
  background: url(~@/assets/goplay/index/top_bg.png) no-repeat center center;
  background-size: 100% 100%;
  .my {
    margin-top: 0.05rem;
    width: 0.7rem;
    height: 100%;
    cursor: pointer;
    .head-box {
      padding: 0.05rem 0.07rem 0.05rem 0.16rem;
      width: 0.57rem;
      height: 0.49rem;
      background: url(~@/assets/goplay/index/head_bg.png) no-repeat left 0.05rem center;
      background-size: contain;
      .head {
        padding: 0.02rem;
        position: relative;
        width: 0.39rem;
        height: 0.39rem;
        border-radius: 100%;
        background: #e6bf7e;
        .img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: inherit;
        }
      }
    }
    .name {
      padding: 0 0.05rem;
      margin-top: 0.05rem;
      width: 96%;
      line-height: 0.15rem;
      font-size: 0.1rem;
      color: #835e4c;
      font-weight: 500;
      text-align: center;
    }
  }
  .target {
    margin-top: 0.05rem;
    display: flex;
    cursor: pointer;
    .head-box {
      width: 0.48rem;
      .head {
        margin: auto;
        position: relative;
        width: 0.28rem;
        height: 0.28rem;
        border-radius: 100%;
        border: 0.01rem solid var(--border-color);
        .img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: inherit;
        }
        .online {
          position: absolute;
          bottom: -0.02rem;
          right: -0.02rem;
          width: 0.08rem;
          height: 0.08rem;
          border-radius: 100%;
          background: #999999;
          box-shadow: 0 0 0.06rem #999999;
          &.ol {
            background: #51c34a;
            box-shadow: 0 0 0.06rem #51c34a;
          }
        }
      }
      .name {
        margin-top: 0.05rem;
        width: 100%;
        line-height: 0.12rem;
        font-size: 0.1rem;
        color: #835e4c;
        font-weight: 500;
        text-align: center;
      }
    }
    .intimacy-box {
      margin: 0.06rem 0 0 0.08rem;
      width: 0.35rem;
      height: 0.4rem;
      .progress-box {
        margin: auto;
        width: 1.2rem;
        height: 1rem;
        transform: scale(0.25);
        transform-origin: 0 0;
        text-align: center;
        .heart {
          position: absolute;
          left: 0.15rem;
          z-index: 2;
          width: 0.9rem;
          height: 0.9rem;
          border-radius: 50%;
          overflow: hidden;
          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: url(~@/assets/goplay/index/progress_bg_light.png) no-repeat;
            background-size: 200% 200%;
            background-position: 200% 200%;
            animation: animation-progress-light 5s linear infinite;
            opacity: 0.2;
          }
          @keyframes animation-progress-light {
            0% {
              background-position: 200% 200%;
            }
            40%,
            100% {
              background-position: -100% -100%;
            }
          }
        }
        .progress {
          position: relative;
          margin: auto;
          width: 100%;
          height: 100%;
          line-height: 0.8rem;
          font-size: 0.24rem;
          color: #d62811;
          font-weight: bold;
          background-image: url(~@/assets/goplay/index/progress_bg.png);
          background-repeat: no-repeat;
          background-position: 0 center;
          background-size: auto 100%;
        }
      }
    }
  }
  .wealth-box {
    margin: 0.1rem 0.08rem 0 0;
    display: flex;
    cursor: pointer;
    .wealth {
      padding: 0 0.04rem 0.04rem 0.04rem;
      margin-left: 0.1rem;
      display: flex;
      align-items: center;
      width: 0.95rem;
      height: 0.25rem;
      background: url(~@/assets/goplay/index/wealth_bg.png) no-repeat center center;
      background-size: 100% 100%;
      font-size: 0.12rem;
      color: #58432f;
      font-family: zihun50hao-baigetianxingti;
      .icon {
        margin: 0.05rem 0 0 -0.12rem;
        width: 0.28rem;
        height: 0.28rem;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .num {
        margin: 0.05rem 0.04rem 0 0.06rem;
        width: 0;
        flex: auto;
        white-space: nowrap;
        color: #fbf4e9;
        font-weight: bold;
        text-shadow: 0 0.01rem 0 #835e4c;
      }
      .recharge {
        margin-top: 0.04rem;
        display: block;
        width: 0.17rem;
        height: 0.17rem;
        pointer-events: auto;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
