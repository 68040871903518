<template>
  <div class="scenery-box">
    <div class="scenery-2"></div>
    <div class="scenery-2-1"></div>
    <div class="scenery-3"></div>
    <div class="scenery-4"></div>
    <div class="scenery-4-1"></div>
    <div class="scenery-4-2"></div>
    <div class="scenery-5"></div>
    <div class="scenery-5-1"></div>
    <div class="scenery-6"></div>
    <div class="scenery-6-1"></div>
    <div class="scenery-7"></div>
    <div class="scenery-8"></div>
    <div class="scenery-8-1"></div>
    <div class="scenery-9"></div>
    <div class="scenery-9-1"></div>
    <div class="scenery-9-1-1"></div>
    <div class="scenery-9-2"></div>
    <div class="scenery-9-3"></div>
    <div class="scenery-10"></div>
    <div class="scenery-10-1"></div>
    <div class="scenery-11"></div>
    <div class="scenery-11-1"></div>
    <div class="scenery-12">
      <div class="shout"></div>
    </div>
    <div class="scenery-13"></div>
    <div class="scenery-14"></div>
    <div class="scenery-15"></div>
    <div class="scenery-16"></div>
    <div class="scenery-16-1"></div>
    <div class="scenery-16-2"></div>
    <div class="scenery-17">
      <div class="shout"></div>
    </div>
    <div class="scenery-18"></div>
    <div class="scenery-19"></div>
    <div class="scenery-19-1"></div>
    <div class="scenery-20"></div>
    <div class="scenery-20-1"></div>
    <div class="scenery-21"></div>
    <div class="scenery-22"></div>
    <div class="scenery-22-1"></div>
    <div class="scenery-23"><div class="scenery-23-1"></div></div>
    <div class="scenery-24"></div>
    <div class="scenery-25"></div>
    <div class="scenery-car"></div>
    <div class="scenery-car-1"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.scenery-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: url(~@/assets/goplay/all.png) no-repeat center center;
  background-size: 100% 100%;
  // pointer-events: none;

  .scenery-2 {
    position: absolute;
    top: 200px;
    left: 350px;
    width: 25px;
    height: 47px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/2.png) left center;
    background-size: auto 47px;
    animation: scenery-2 1s steps(4) infinite;
    transform: scale(0.9);
  }
  .scenery-2-1 {
    position: absolute;
    top: 200px;
    right: 100px;
    width: 25px;
    height: 47px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/2.png) left center;
    background-size: auto 47px;
    animation: scenery-2 1s steps(4) infinite;
    transform: scale(0.9);
  }
  .scenery-3 {
    position: absolute;
    top: 260px;
    left: 500px;
    width: 22px;
    height: 52px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/3.png) left center;
    background-size: auto 52px;
    transform: scale(0.9);
  }
  .scenery-4 {
    position: absolute;
    top: 240px;
    right: 40px;
    width: 28px;
    height: 60px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/4.png) left center;
    background-size: auto 60px;
    animation: scenery-4 2.5s steps(4) infinite;
    transform: scale(0.9);
  }

  .scenery-4-1 {
    position: absolute;
    bottom: 120px;
    left: 80px;
    width: 28px;
    height: 60px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/4-1.png) center center;
    background-size: contain;
    transform: scale(0.9) rotateY(180deg);
  }
  .scenery-4-2 {
    position: absolute;
    top: 340px;
    left: 450px;
    width: 28px;
    height: 60px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/4.png) left center;
    background-size: auto 60px;
    animation: scenery-4 2.5s steps(4) infinite;
    transform: scale(0.9);
  }
  .scenery-5 {
    position: absolute;
    bottom: 265px;
    right: 380px;
    width: 40px;
    height: 68px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/5.png) left center;
    background-size: auto 68px;
    animation: scenery-5 1.5s steps(8) infinite;
    transform: scale(0.9);
  }
  .scenery-5-1 {
    position: absolute;
    top: 223px;
    left: 400px;
    width: 40px;
    height: 68px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/5.png) left center;
    background-size: auto 68px;
    animation: scenery-5 1.5s steps(8) infinite;
    transform: scale(0.9);
  }
  .scenery-6 {
    position: absolute;
    bottom: 500px;
    right: 380px;
    width: 23px;
    height: 38px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/6.png) left center;
    background-size: auto 38px;
    animation: scenery-6 1s steps(6) infinite;
    transform: rotateY(180deg);
  }
  .scenery-6-1 {
    position: absolute;
    top: 550px;
    left: 150px;
    z-index: 5;
    width: 23px;
    height: 38px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/6.png) left center;
    background-size: auto 38px;
    animation: scenery-6 1s steps(6) infinite;
  }
  // 原始: left 750 现:410 750
  .scenery-7 {
    position: absolute;
    top: 410px;
    left: 550px;
    width: 29px;
    height: 41px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/7.png) left center;
    background-size: auto 41px;
    animation: scenery-7 1.5s steps(6) infinite;
  }
  .scenery-8 {
    position: absolute;
    top: 50px;
    left: 780px;
    width: 23px;
    height: 55px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/8.png) left center;
    background-size: auto 55px;
    animation: scenery-8 1.5s steps(6) infinite;
  }
  .scenery-8-1 {
    position: absolute;
    top: 280px;
    left: 70px;
    width: 23px;
    height: 55px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/8.png) left center;
    background-size: auto 55px;
    animation: scenery-8 1.5s steps(6) infinite;
  }
  .scenery-9 {
    position: absolute;
    top: 510px;
    left: 550px;
    width: 48px;
    height: 46px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/9.png) left center;
    background-size: auto 46px;
    animation: scenery-9 1.5s steps(4) infinite;
  }
  .scenery-9-1 {
    position: absolute;
    top: 500px;
    left: 430px;
    width: 48px;
    height: 46px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/9-1.png) left center;
    background-size: auto 46px;
    animation: scenery-9 1.5s steps(4) infinite;
  }
  .scenery-9-1-1 {
    position: absolute;
    bottom: 70px;
    left: 450px;
    width: 48px;
    height: 46px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/9-4-2.png) left center;
    background-size: auto 46px;
    animation: scenery-9 1.5s steps(4) infinite;
  }
  .scenery-9-2 {
    position: absolute;
    top: 430px;
    left: 1050px;
    width: 48px;
    height: 46px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/9-4-2.png) left center;
    background-size: auto 46px;
    animation: scenery-9 1.5s steps(4) infinite;
  }
  .scenery-9-3 {
    position: absolute;
    top: 150px;
    left: 800px;
    width: 48px;
    height: 46px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/9.png) left center;
    background-size: auto 46px;
    animation: scenery-9-2 1.5s steps(4) infinite;
  }

  .scenery-10 {
    position: absolute;
    top: 500px;
    left: 1040px;
    width: 25px;
    height: 48px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/10.png) left center;
    background-size: auto 48px;
  }
  .scenery-10-1 {
    position: absolute;
    bottom: 300px;
    left: 840px;
    width: 25px;
    height: 48px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/10.png) left center;
    background-size: auto 48px;
    transform: rotateY(175deg);
  }
  .scenery-11 {
    position: absolute;
    bottom: 330px;
    left: 30px;
    width: 68px;
    height: 55px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/11.png) left center;
    background-size: auto 55px;
    animation: scenery-11 2.5s steps(4) infinite;
  }
  .scenery-11-1 {
    position: absolute;
    bottom: 160px;
    left: 800px;
    width: 68px;
    height: 55px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/11.png) left center;
    background-size: auto 55px;
    animation: scenery-11 2.5s steps(4) infinite;
  }
  .scenery-12 {
    position: absolute;
    top: 230px;
    left: 40px;
    z-index: 5;
    width: 20px;
    height: 40px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/12.png) left center;
    background-size: auto 40px;

    .shout {
      position: absolute;
      bottom: 27px;
      left: 15px;
      width: 0;
      animation: scenery-shout 1.5s linear infinite;
      transform-origin: left center;
      overflow: hidden;
      &::before {
        content: '';
        display: block;
        width: 30px;
        height: 12px;
        background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/12-1.png) no-repeat left center;
        background-size: contain;
      }
    }
  }

  .scenery-13 {
    position: absolute;
    bottom: 390px;
    left: 960px;
    width: 20px;
    height: 40px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/13.png) left center;
    background-size: auto 40px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 20px;
      left: 11px;
      width: 30px;
      height: 50px;
      background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/balloon.png) no-repeat center center;
      background-size: contain;
    }
  }
  .scenery-14 {
    position: absolute;
    top: 530px;
    left: 350px;
    width: 25px;
    height: 47px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/14.png) left center;
    background-size: auto 47px;
    transform: rotateY(167deg);
  }
  .scenery-15 {
    position: absolute;
    bottom: 200px;
    left: 650px;
    width: 20px;
    height: 40px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/15.png) left center;
    background-size: auto 40px;
  }
  .scenery-16 {
    position: absolute;
    top: 200px;
    right: 600px;
    width: 20px;
    height: 40px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/16.png) left center;
    background-size: auto 40px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 20px;
      right: 0;
      width: 23px;
      height: 46px;
      background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/balloon.png) no-repeat center center;
      background-size: contain;
    }
  }
  .scenery-16-1 {
    position: absolute;
    top: 60px;
    left: 810px;
    width: 23px;
    height: 40px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/16-1.png) left center;
    background-size: auto 40px;
  }
  .scenery-16-2 {
    position: absolute;
    top: 290px;
    left: 100px;
    width: 20px;
    height: 40px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/16.png) left center;
    background-size: auto 40px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 40px;
      right: 0;
      width: 23px;
      height: 46px;
      background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/balloon.png) no-repeat center center;
      background-size: contain;
    }
  }
  .scenery-17 {
    position: absolute;
    top: 320px;
    left: 960px;
    width: 82px;
    height: 40px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/17.png) left center;
    background-size: auto 40px;
    .shout {
      position: absolute;
      top: -3px;
      left: 77px;
      width: 0;
      animation: scenery-shout 1.5s linear infinite;
      transform-origin: left center;
      overflow: hidden;
      &::before {
        content: '';
        display: block;
        width: 30px;
        height: 12px;
        background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/17-1.png) no-repeat left center;
        background-size: contain;
      }
    }
  }
  .scenery-18 {
    position: absolute;
    top: 260px;
    right: 510px;
    width: 20px;
    height: 52px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/18.png) left center;
    background-size: auto 52px;
  }
  .scenery-19 {
    position: absolute;
    top: 607px;
    right: 80px;
    z-index: 5;
    width: 52px;
    height: 52px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/19.png) left center;
    background-size: auto 52px;
  }
  .scenery-19-1 {
    position: absolute;
    top: 662px;
    right: 30px;
    width: 35px;
    height: 56px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/19-1.png) left center;
    background-size: auto 56px;
    &::before {
      content: '';
      position: absolute;
      top: 1px;
      right: 16px;
      width: 20px;
      height: 20px;
      display: block;
      background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/19-2.png) no-repeat center center;
      background-size: contain;
      animation: scenery-flash-lamp 3s ease infinite both;
    }
  }
  .scenery-20 {
    position: absolute;
    top: 500px;
    right: 430px;
    width: 68px;
    height: 55px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/20.png) left center;
    background-size: auto 55px;
    animation: scenery-20 1.5s steps(6) infinite;
  }
  .scenery-20-1 {
    position: absolute;
    top: 250px;
    right: 230px;
    width: 68px;
    height: 55px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/20.png) left center;
    background-size: auto 55px;
    animation: scenery-20 1.5s steps(6) infinite;
  }
  .scenery-21 {
    position: absolute;
    bottom: 100px;
    left: 400px;
    width: 34px;
    height: 54px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/21.png) left center;
    background-size: auto 54px;
    animation: scenery-21 2.5s steps(4) infinite;
  }
  .scenery-22 {
    position: absolute;
    bottom: 220px;
    left: 500px;
    width: 39px;
    height: 48px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/22.png) left center;
    background-size: auto 48px;
  }
  .scenery-22-1 {
    position: absolute;
    top: 420px;
    right: 30px;
    width: 39px;
    height: 48px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/22.png) left center;
    background-size: auto 48px;
  }
  .scenery-23 {
    position: absolute;
    bottom: 120px;
    right: 180px;
    width: 138px;
    height: 116px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/23.png) left center;
    background-size: auto 116px;

    .scenery-23-1 {
      position: absolute;
      bottom: 40px;
      left: 27px;
      width: 34px;
      height: 54px;
      overflow: hidden;
      animation: scenery-23-1 1s steps(4) infinite;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 34px;
        height: 54px;
        background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/23-1.png) left center;
        background-size: auto 54px;
      }
    }
  }
  .scenery-24 {
    position: absolute;
    bottom: 220px;
    left: 1000px;
    width: 15px;
    height: 56px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/24.png) left center;
    background-size: auto 56px;
  }
  .scenery-25 {
    position: absolute;
    bottom: 240px;
    right: 520px;
    width: 26px;
    height: 60px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/25.png) left center;
    background-size: auto 60px;
    transform: scale(0.8);
  }
  .scenery-car {
    position: absolute;
    top: 280px;
    left: 560px;
    width: 90px;
    height: 65px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/car-1.png) no-repeat center center;
    background-size: contain;
    pointer-events: auto;
  }
  .scenery-car-1 {
    position: absolute;
    top: 530px;
    left: 910px;
    width: 90px;
    height: 65px;
    background: url(https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/scenery/car-1.png) no-repeat center center;
    background-size: contain;
    pointer-events: auto;
    transform: rotateY(175deg);
  }
}

@keyframes scenery-2 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100px 0;
  }
}
@keyframes scenery-3 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -132px 0;
  }
}
@keyframes scenery-4 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -110px 0;
  }
}
@keyframes scenery-5 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -320px 0;
  }
}
@keyframes scenery-6 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -138px 0;
  }
}
@keyframes scenery-7 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -174px 0;
  }
}
@keyframes scenery-8 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -136px 0;
  }
}
@keyframes scenery-9 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 190px 0;
  }
}
@keyframes scenery-9-2 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 190px 0;
  }
}
@keyframes scenery-9-4 {
  0% {
    transform: translate(110px, 940px);
  }
  100% {
    transform: translate(-650px, 940px);
  }
}
@keyframes scenery-shout {
  0% {
    width: 0;
  }
  100% {
    width: 30px;
  }
}

@keyframes scenery-11 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -272px 0;
  }
}
@keyframes scenery-20 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -408px 0;
  }
}
@keyframes scenery-21 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -136px 0;
  }
}
@keyframes scenery-23-1 {
  0% {
    height: 0;
    opacity: 1;
  }
  99% {
    height: 54px;
  }
  100% {
    height: 54px;
    opacity: 0.3;
  }
}
@keyframes scenery-flash-lamp {
  0%,
  50%,
  100% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
}
@media screen and (min-width: 2000px) {
  .scenery-box {
    .scenery-2 {
      top: 70px;
      left: 400px;
    }
    .scenery-3 {
      top: 200px;
      left: 800px;
    }
    .scenery-4-1 {
      bottom: 80px;
      left: 40px;
    }
    .scenery-5 {
      right: 480px;
    }
    .scenery-6 {
      bottom: 600px;
    }
    .scenery-9 {
      top: 570px;
      left: 600px;
    }
    .scenery-9-1-1 {
      bottom: 370px;
      left: 550px;
    }
    .scenery-9-2 {
      top: 400px;
      left: 980px;
    }
    .scenery-10-1 {
      left: 900px;
      bottom: 200px;
    }
    .scenery-11-1 {
      left: 950px;
    }
    .scenery-14 {
      top: 630px;
      left: 450px;
    }
    .scenery-15 {
      bottom: 150px;
    }
    .scenery-16 {
      bottom: 350px;
      right: 540px;
    }
    .scenery-20 {
      top: 400px;
    }
    .scenery-21 {
      bottom: 70px;
    }
    .scenery-25 {
      bottom: 200px;
      right: 420px;
    }
    .scenery-car {
      top: 250px;
      left: 1200px;
    }
  }
}
@media screen and (max-width: 1800px) and (min-width: 1441px) {
  .scenery-box {
    .scenery-2 {
      top: 260px;
    }
    .scenery-3 {
      top: 300px;
    }
    .scenery-4-1 {
      bottom: 180px;
    }
    .scenery-5 {
      bottom: 240px;
      right: 300px;
    }
    .scenery-6 {
      bottom: 470px;
      right: 330px;
    }
    .scenery-7 {
      top: 410px;
      left: 500px;
    }
    .scenery-8 {
      left: 700px;
    }
    .scenery-9-1-1 {
      bottom: 190px;
      left: 550px;
    }
    .scenery-9-2 {
      top: 330px;
      left: 850px;
    }
    .scenery-9-3 {
      top: 260px;
    }
    .scenery-10 {
      top: 440px;
      left: 1000px;
    }
    .scenery-11-1 {
      left: 950px;
    }
    .scenery-13 {
      bottom: 270px;
      left: 500px;
    }
    .scenery-14 {
      top: 630px;
      left: 350px;
    }
    .scenery-15 {
      bottom: 260px;
      left: 800px;
    }
    .scenery-16 {
      right: 450px;
    }
    .scenery-18 {
      top: 230px;
      right: 370px;
    }
    .scenery-16-1 {
      left: 750px;
    }
    .scenery-17 {
      top: 360px;
    }
    .scenery-19 {
      top: 707px;
      right: 40px;
    }
    .scenery-19-1 {
      top: 762px;
    }
    .scenery-20 {
      top: 450px;
      right: 300px;
    }
    .scenery-21 {
      left: 550px;
    }
    .scenery-22 {
      bottom: 150px;
      left: 450px;
    }
    .scenery-22-1 {
      top: 270px;
      right: 100px;
    }
    .scenery-23 {
      bottom: 100px;
      right: 160px;
    }
    .scenery-24 {
      bottom: 320px;
    }
    .scenery-25 {
      bottom: 540px;
      right: 320px;
    }
    .scenery-car {
      top: 160px;
      left: 780px;
    }
    .scenery-car-1 {
      top: 600px;
    }
  }
}
@media screen and (max-width: 1440px) {
  .scenery-box {
    .scenery-3 {
      left: 405px;
    }
    .scenery-5-1 {
      top: 280px;
      left: 350px;
    }
    .scenery-6 {
      bottom: 340px;
    }
    .scenery-6-1 {
      top: 412px;
      left: 112px;
    }
    .scenery-7 {
      top: 307.5px;
      left: 412px;
    }
    .scenery-8 {
      top: 50px;
      left: 545px;
    }
    .scenery-9 {
      top: 402px;
      left: 412.5px;
    }
    .scenery-9-1-1 {
      bottom: 350px;
      left: 680px;
    }
    .scenery-9-2 {
      left: 920px;
    }
    .scenery-9-3 {
      left: 870px;
    }
    .scenery-10 {
      left: 1240px;
    }
    .scenery-10-1 {
      bottom: 450px;
      left: 700px;
    }
    .scenery-11-1 {
      bottom: 200px;
      left: 690px;
    }
    .scenery-13 {
      bottom: 540px;
      left: 590px;
    }
    .scenery-14 {
      top: 450px;
      left: 320px;
    }
    .scenery-15 {
      bottom: 100px;
      left: 750px;
    }
    .scenery-16 {
      top: 250px;
    }
    .scenery-16-1 {
      left: 600px;
    }
    .scenery-17 {
      left: 960px;
      top: 220px;
    }
    .scenery-20 {
      right: 130px;
    }
    .scenery-20-1 {
      top: 200px;
      right: 200px;
    }
    .scenery-21 {
      left: 300px;
    }
    .scenery-22 {
      bottom: 150px;
      left: 400px;
    }
    .scenery-23 {
      bottom: 80px;
      right: 180px;
    }
    .scenery-25 {
      bottom: 200px;
      right: 280px;
    }
  }
}
</style>
