<template>
  <div class="top-banner hidden-xs">
    <div class="background-image" :style="{ backgroundImage: 'url(' + UsImgUrl + ')' }"></div>
    <router-link :to="path">
      <div v-if="path == '/aboutUs'" class="inner-txt">关于我们</div>
      <div v-if="path == '/solution'" class="inner-txt">解决方案</div>
      <div v-if="path == '/aboutProduct'" class="inner-txt">关于产品</div>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      UsImgUrl: require('@/assets/product/banner.jpg')
    };
  },
  computed: {
    path() {
      return this.$route.path;
    }
  }
};
</script>
<style lang="less">
.top-banner {
  position: relative;
  width: 100%;
  height: 25em;
  .background-image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: no-repeat center center;
    background-size: cover;
  }
  .inner-txt {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    font-size: 2.1em;
    color: #ffffff;
  }
}
</style>
