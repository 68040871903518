<template>
  <div class="productActive">
    <Header />
    <div class="about-product ">
      <Banner />
      <div class="content-inner">
        <Title :title="titleWord" />
        <Introduce :type="1" />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/common/header';
import Footer from '@/components/common/footer';
import Title from '@/components/about/title';
import Banner from '@/components/about/banner';
import Introduce from '@/components/product/introduce';
export default {
  components: {
    Header,
    Footer,
    Banner,
    Title,
    Introduce
  },
  data() {
    return {
      titleWord: '寻找灵魂伴侣，总共分三步'
    };
  }
};
</script>
<style lang="less" scoped>
.productActive {
  background: url(~@/assets/product/product_character.png) no-repeat left 20% bottom;
  background-size: 14.3% auto;
}
.about-product {
  margin-bottom: 244px;
  .content-inner {
    margin: auto;
    width: 49.7%;
  }
  .footer {
    margin-top: 317px;
  }
}
@media screen and (max-width: 1661px) {
  .about-product {
    .content-inner {
      width: 65%;
    }
  }
}
</style>
