<template>
  <div class="go-play-container">
    <div class="container-box" :class="mask ? 'container-mask' : ''" @touchmove.prevent @click="onReset">
      <div ref="container" class="container">
        <div class="bg"></div>
        <scenery-box></scenery-box>
        <div class="house-box">
          <div v-for="item in newList" :id="item.major" :key="item.major" class="house" :class="`house-${item.major}`" :title="item.title">
            <div class="house-node" :class="{ selected: item.isSelect, prominent: item.isProminent }">
              <div class="house-shadow"></div>
              <div v-if="item.id == 'TEA_HOUSE'" class="thing">
                <div class="scenery-duck-1"></div>
                <div class="scenery-duck-2"></div>
              </div>
              <img class="house-img" :src="item.imgs[0]" alt="" />
              <div class="hot-zone" @click.stop="onSelectHouse(item)"></div>
              <div class="icon-arrow"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 定位内容显示区 -->
    <div v-show="positioning" class="layout-box">
      <div class="layout-top">
        <user-info />
      </div>
      <div class="layout-bottom">
        <div class="bottom-menu-box">
          <div class="menu-list">
            <div class="item" @click="onSelectHouse">
              <xld-image class="icon" path="common/index" name="icon-diary" title="日记" />
            </div>
            <div class="item" @click="onSelectHouse">
              <xld-image class="icon" path="common/index" name="icon-message" title="消息" />
            </div>
            <div class="item" @click="onSelectHouse">
              <xld-image class="icon" path="common/index" name="icon-achievement" title="成就" />
            </div>
            <div class="item" @click="onSelectHouse">
              <img v-if="relevantInfo.sex == 1" src="@/assets/goplay/index/icon_my_male.png" class="icon" alt="我的" />
              <img v-else src="@/assets/goplay/index/icon_my_female.png" class="icon" alt="我的" />
            </div>
            <div class="item" @click="onSelectHouse">
              <xld-image class="icon" path="common/index" name="icon-letter" title="飞鸽传书" />
            </div>
            <div class="item" @click="onSelectHouse">
              <xld-image class="icon" path="common/index" name="icon-voicemail" title="留声电台" />
            </div>
            <div class="item" @click="onSelectHouse">
              <xld-image class="icon" path="common/index" name="icon-chatroom" title="畅聊" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗 -->
    <el-dialog :visible.sync="showRegister" class="dialog-register" :show-close="false" :close-on-click-modal="false">
      <!-- 登录 -->
      <div v-if="step == 1" class="content-login">
        <img src="@/assets/goplay/icon_close.png" @click="showRegister = false" class="icon-close" alt="" />
        <div class="login-left">
          <img src="@/assets/goplay/login.png" class="logo" alt="" />
          <!-- <div class="code"></div> -->
          <div class="code">
            <div v-if="codeTip" class="tip" @click="showRefresh">已过期<br />点击刷新二维码</div>
            <img :src="qrcodeInfo.e" alt="" />
          </div>
          <div class="tip">打开微信 扫码登录</div>
        </div>
        <div class="login-right">
          <div class="txt">手机号登录</div>
          <el-form ref="form">
            <el-form-item class="form-item">
              <el-input v-model="phone" placeholder="请输入手机号" type="digit" maxlength="11"></el-input>
            </el-form-item>
            <el-form-item prop="code" class="form-item code-box">
              <el-input v-model="code" auto-complete="off" type="digit" placeholder="请输入短信验证码" @keyup.enter.native="onSign" maxlength="6"> </el-input>
              <div class="login-code">
                <div v-if="countDown >= 60" class="getcode" @click="getCode">
                  <span class="time">获取短信验证码</span>
                </div>
                <div v-else class="getcode">
                  还有<span class="time">{{ countDown }}s</span>重新发送
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSign">登录</el-button>
            </el-form-item>
          </el-form>
          <div class="agree"><el-checkbox v-model="agree">我已阅读并同意</el-checkbox><router-link to="/user-agreement" target="_blank">《用户协议》</router-link>和<router-link to="/privacy-policy" target="_blank">《隐私政策》</router-link></div>
        </div>
      </div>
      <!-- 性别 -->
      <div v-else-if="step == 2" class="content-sex">
        <img src="@/assets/goplay/icon_close.png" @click="showRegister = false" class="icon-close" alt="" />
        <div class="title">你是女生还是男生</div>
        <div class="sex-box">
          <div class="item" :class="{ selected: userInfo.sex == 2 }" @click="userInfo.sex = 2">
            <div class="head girl"></div>
          </div>
          <div class="item" :class="{ selected: userInfo.sex == 1 }" @click="userInfo.sex = 1">
            <div class="head boy"></div>
          </div>
        </div>
        <div class="birthday-box">
          <el-date-picker type="date" placeholder="请选择出生日期" v-model="userInfo.birthday" style="width: 100%;" :change="changeDate" value-format="yyyy-MM-dd" :picker-options="forbiddenStartTime"></el-date-picker>
        </div>
        <el-button type="primary" @click="onDetermine" class="determine">确定</el-button>
      </div>
      <!-- 标签 -->
      <div v-else-if="step == 3" class="content-lable">
        <img src="@/assets/goplay/icon_close.png" @click="showRegister = false" class="icon-close" alt="" />
        <div class="tip1">寻找更多志同道合</div>
        <div class="top-box">
          <div class="tip2">选择个人标签</div>
          <div class="nums">
            已选
            <span class="num"> {{ userTags.length }} </span>/ 10
          </div>
        </div>
        <div class="tags-list">
          <div v-for="(item, index) in sysTags" :key="index" class="item-box">
            <div class="categor-name">{{ item.categoryName }}</div>
            <div class="label-info-list">
              <div v-for="labelitem in item.labelInfoList" :key="labelitem.labelId" class="item">
                <div v-if="labelitem.status" class="custom-button" disabled @click="del(labelitem)">{{ labelitem.labelName }}</div>
                <div v-else class="custom-button" plain @click="updateTag(labelitem)">{{ labelitem.labelName }}</div>
              </div>
            </div>
          </div>
        </div>
        <el-button type="primary" @click="onDetermineTag" class="determine">确定</el-button>
      </div>
      <div v-else class="content-code">
        <img src="@/assets/goplay/icon_close.png" @click="showRegister = false" class="icon-close" alt="" />
        <img src="@/assets/goplay/dialog-img1.png" class="dialog-img1" alt="" />
        <img src="@/assets/goplay/dialog-word.png" class="word" alt="" />
        <div class="code"></div>
        <div class="tit">扫码进入移动端 体验更多功能玩法</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import mixin from './js/mixin';
import getHouseList from './js/houseList';
import getGuideInfo from './js/getGuideInfo';
import sceneryBox from './scenery-box';
import userInfo from './components/user-info';
import { phoneValid } from '@/utils';
import { setToken, getToken } from '@/utils/auth';
export default {
  components: {
    sceneryBox,
    userInfo
  },
  mixins: [mixin],
  data() {
    return {
      houseList: getHouseList(),
      getGuideInfo,
      step: 1, // 步骤
      showRegister: false,
      positioning: false,
      phone: '',
      code: '',
      agree: false,
      countDownTimer: null, //
      countDown: 60, // 发送验证码倒计时
      // 选择生日
      userInfo: {
        sex: 2,
        birthday: ''
      },
      userTags: [],
      sysTags: [],
      forbiddenStartTime: {
        disabledDate(time) {
          return time.getTime() > new Date() - 8.64e7;
        }
      },
      qrcodeInfo: {},
      // 轮序查找支付结果
      limitTimes: 0, // 轮序次数
      limitTimesMax: 150, // 最大轮序限制
      releaseLoading: false,
      codeTip: false
    };
  },
  watch: {
    // relevantInfo() {
    //   const { sex, lableStatus } = this.relevantInfo;
    //   console.log(this.relevantInfo,"@@@444");
    //   if (sex != 0 && !lableStatus) {
    //     this.getSysTags();
    //   }
    //   if (sex != 0) {
    //     this.positioning = true;
    //   }
    // }
  },
  computed: {
    ...mapGetters(['relevantInfo']),
    mask() {
      if (this.showRegister) {
        return true;
      }
      return false;
    }
  },
  beforeDestroy() {
    clearInterval(this.countDownTimer);
    this.countDownTimer = null;
  },
  mounted() {
    if (getToken()) {
      this.$store.dispatch('getRelevantInfo');
      setTimeout(() => {
        const { sex, lableStatus } = this.relevantInfo;
        if (sex == 0) {
          this.showRegister = true;
          this.step = 2;
          return false;
        }
        if (lableStatus == false) {
          this.getSysTags();
          this.showRegister = true;
          this.step = 3;
          return false;
        }
      }, 300);
    } else {
      this.showRegister = true;
      this.step = 1;
      this.getcode();
    }
  },
  methods: {
    // 二维码
    getcode() {
      this.$http.get(`/user/login/official_account/login/qrcode`).then((res) => {
        const { data } = res;
        this.qrcodeInfo = data;
        this.getState();
      });
    },
    showRefresh() {
      this.limitTimes = 0;
      this.codeTip = false;
      this.getcode();
    },
    getState() {
      this.limitTimes++;
      if (this.limitTimes > this.limitTimesMax) {
        this.codeTip = true;
        return false;
      }
      this.$http.get(`/user/login/official_account/login/state/${this.qrcodeInfo.t}`).then((res) => {
        const info = res.data;
        if (info) {
          setToken(res.data);
          this.$store.dispatch('getRelevantInfo');
          setTimeout(() => {
            this.register();
          }, 100);
        } else {
          setTimeout(() => {
            this.getState();
          }, 2000);
        }
      });
    },
    // 获取系统标签列表
    getSysTags() {
      this.$http.get(`/user/personalLabel/system/list`).then((res) => {
        this.sysTags = res.data || [];
      });
    },
    getCode() {
      if (!phoneValid(this.phone)) {
        this.$message({
          showClose: true,
          message: '请输入正确的手机号',
          type: 'error'
        });
        return false;
      }
      if (this.countDownTimer) {
        return false;
      }
      this.startCountDown();
      this.$http
        .get(`/user/login/smsCode/${this.phone}`)
        .then(() => {})
        .catch(() => {
          clearInterval(this.countDownTimer);
          this.countDownTimer = null;
          this.countDown = 60;
        });
    },
    // 开启发送验证码的倒计时
    startCountDown() {
      this.countDown--;
      this.countDownTimer = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--;
        }
        if (this.countDown <= 0) {
          clearInterval(this.countDownTimer);
          this.countDownTimer = null;
          this.countDown = 60;
        }
      }, 1000);
    },
    onSign() {
      if (!phoneValid(this.phone)) {
        this.$message({
          showClose: true,
          message: '请输入正确的手机号',
          type: 'error'
        });
        return false;
      } else if (!this.code || this.code.length !== 6) {
        this.$message({
          showClose: true,
          message: '请输入正确的验证码',
          type: 'error'
        });
        return;
      } else if (!this.agree) {
        this.$message({
          showClose: true,
          message: '请勾选用户协议和隐私协议',
          type: 'error'
        });
        return;
      }
      this.$http
        .post(`/user/login/phone`, {
          phoneNumber: this.phone,
          smsCode: this.code
        })
        .then((res) => {
          setToken(res.data);
          this.$store.dispatch('getRelevantInfo');
          setTimeout(() => {
            this.register();
          }, 300);
        });
    },
    // 确定出生日期
    onDetermine() {
      const { sex, birthday } = this.userInfo || {};

      if (!birthday) {
        this.$message({
          showClose: true,
          message: '请选择出生日期',
          type: 'error'
        });
        return false;
      }
      const data = { sex, birthday };
      this.$http.put(`/user/info`, data).then(() => {
        this.$store.dispatch('getRelevantInfo');
        this.getSysTags();
        this.step = 3;
      });
    },
    changeDate(val) {
      this.userInfo.birthday = val;
    },
    // 注册
    register() {
      const { sex, lableStatus } = this.relevantInfo;
      if (sex == 0) {
        this.showRegister = true;
        this.step = 2;
        return false;
      }
      if (lableStatus == false) {
        this.getSysTags();
        this.showRegister = true;
        this.step = 3;
        return false;
      }
      this.showRegister = true;
      this.step = 4;
      this.positioning = true;
    },
    // 设置标签
    updateTag(labelitem) {
      const { labelName } = labelitem;
      if (this.userTags.length >= 10) {
        this.$message({
          showClose: true,
          message: '最多添加10个标签',
          type: 'error'
        });
        return false;
      }
      if (this.userTags.some((x) => x.labelName == labelName)) {
        this.$message({
          showClose: true,
          message: '标签不能重复',
          type: 'error'
        });
        return false;
      }
      this.userTags.push(labelitem);
      labelitem.status = true;
      this.sysTags.map((x) => {
        if (x.categoryId == labelitem.categoryId) {
          x.labelInfoList.filter((y) => {
            if (y.labelName == labelitem.labelName) {
              return (y.status = true);
            }
          });
          return x;
        }
      });
    },
    // 删除标签
    del(labelitem) {
      labelitem.status = false;
      this.userTags = this.userTags.filter((y) => {
        return y.labelName != labelitem.labelName;
      });
      this.sysTags.map((x) => {
        if (x.categoryId == labelitem.categoryId) {
          x.labelInfoList.filter((y) => {
            if (y.labelName == labelitem.labelName) {
              return (y.status = false);
            }
          });
          return x;
        }
      });
    },
    onDetermineTag() {
      const userTags = [];
      this.userTags.map((x) => {
        userTags.push(x.labelId);
        return x;
      });
      if (!this.userTags.length) {
        this.$message({
          showClose: true,
          message: '个人标签不能为空',
          type: 'error'
        });
        return false;
      }
      if (this.userTags.length < 5) {
        this.$message({
          showClose: true,
          message: '至少选择5个标签哦',
          type: 'error'
        });
        return false;
      }
      if (this.userTags.length >= 10) {
        this.$message({
          showClose: true,
          message: '最多添加10个标签',
          type: 'error'
        });
        return false;
      }
      this.$http.post(`/user/personalLabel/update`, userTags).then(() => {
        this.$store.dispatch('getRelevantInfo');
        this.step = 4;
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import './style/house-node.less';
@import './style/house-list.less';

.go-play-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .container-box {
    width: 100%;
    height: 100%;

    &.container-mask {
      filter: blur(1px);
    }

    .container {
      width: 100%;
      height: 100%;
      cursor: default;

      .bg {
        width: 100%;
        height: 100%;
        display: block;
        pointer-events: none;
        background: url(~@/assets/goplay/tag.jpg) no-repeat;
        background-size: cover;
      }
    }
  }

  .layout-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    pointer-events: none;

    .layout-top {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      pointer-events: auto;
    }

    .layout-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      pointer-events: auto;

      .bottom-menu-box {
        padding: 15px 50px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url(~@/assets/goplay/index/bottom-0.png) no-repeat center bottom;
        background-size: 100% auto;
        pointer-events: auto;

        .menu-list {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 0;
          height: 54px;

          .item {
            position: relative;
            display: block;
            width: 55px;
            height: 59px;

            .icon {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

::v-deep .el-dialog__wrapper {
  margin: auto;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-dialog {
    width: 100%;
    height: 100%;
    margin: 0 auto !important;
    background: transparent;
    border-radius: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  &.dialog-register {
    .content-login {
      width: 920px;
      border-radius: 25px;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .icon-close {
        position: absolute;
        right: 25px;
        top: 25px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .login-left {
        padding: 89px 117px 103px 123px;
        width: 50%;
        border-right: 1px solid rgba(143, 143, 143, 0.1);

        .logo {
          margin: 0 auto;
          width: 136px;
          display: block;
        }

        .code {
          // margin: 34px 0 20px;
          // width: 225px;
          // height: 225px;
          // background: url(~@/assets/goplay/code.png) no-repeat center;
          // background-size: contain;
          // box-shadow: 0px 1px 4px 0px rgba(4, 0, 0, 0.15);
          // border-radius: 15px;
          margin: 34px 0 20px;
          position: relative;
          width: 225px;
          height: 225px;
          box-shadow: 0px 1px 4px 0px rgba(4, 0, 0, 0.15);
          border-radius: 15px;
          .tip {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: inherit;
          }
        }

        .tip {
          font-size: 18px;
          font-weight: 600;
          color: #333333;
          text-align: center;
        }
      }

      .login-right {
        padding: 60px;
        width: 50%;

        .txt {
          font-size: 20px;
          font-weight: 600;
          color: #333333;
          text-align: center;
        }

        .el-form {
          margin-top: 30px;

          ::placeholder {
            color: #999999;
            opacity: 0.5;
          }

          .el-form-item {
            margin-bottom: 20px;
            cursor: pointer;

            &.form-item {
              .el-form-item__content {
                padding: 0 26px;
                background: #f8f8f8;
                border-radius: 28px;
              }
            }

            .el-form-item__content {
              .el-input {
                .el-input__inner {
                  padding: 0;
                  height: 55px;
                  background: transparent;
                  border: 0;
                }
              }

              .login-code {
                font-size: 16px;
                font-weight: 400;
                color: #6497ff;
              }
            }

            &.code-box {
              margin-bottom: 40px;

              .el-form-item__content {
                display: flex;
                justify-content: start;
                align-items: center;

                .el-input {
                  width: 55%;
                }
              }
            }

            .el-button {
              width: 100%;
              height: 55px;
              background: #e6bf7e;
              border-radius: 28px;
              border: 0;
              font-size: 18px;
              font-weight: 600;
              color: #ffffff;
            }
          }
        }

        .agree {
          text-align: center;

          .el-checkbox__inner {
            border-radius: 50%;
          }

          a {
            color: #6497ff;
          }
        }
      }
    }

    .content-sex {
      padding: 100px 70px 110px;
      width: 550px;
      background: #ffffff;
      position: relative;
      border-radius: 25px;
      position: relative;

      .icon-close {
        position: absolute;
        top: 25px;
        right: 25px;
        width: 20px;
        display: block;
      }

      .title {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        text-align: center;
      }

      .sex-box {
        margin: 40px auto 50px;
        display: flex;
        justify-content: space-around;

        .item {
          padding: 5px;
          width: 170px;
          height: 170px;
          background: #f8f8f8;
          box-shadow: 0px 1px 4px 0px rgba(4, 0, 0, 0.15);
          border-radius: 15px;
          cursor: pointer;

          .head {
            width: 100%;
            height: 100%;

            &.girl {
              background: url(~@/assets/goplay/image_girl.png) no-repeat center center;
              background-size: contain;
            }

            &.boy {
              background: url(~@/assets/goplay/image_boy.png) no-repeat center center;
              background-size: contain;
            }
          }

          &.selected {
            background: #edc88a;

            .girl {
              background-image: url(~@/assets/goplay/image_girl-a.png);
            }

            .boy {
              background-image: url(~@/assets/goplay/image_boy-a.png);
            }
          }
        }
      }

      .birthday-box {
        width: 410px;
        height: 60px;
        background: #f8f8f8;
        border-radius: 10px;

        .el-input__prefix {
          display: none;
        }

        .el-input__inner {
          padding-left: 20px;
          width: 100%;
          height: 60px;
          border: 0;
          line-height: 60px;
          background: transparent url(~@/assets/goplay/icon_down.png) no-repeat right 20px center;
          background-size: 20px auto;
        }
      }

      .determine {
        margin: 30px auto 0;
        width: 80%;
        height: 55px;
        background: #e6bf7e;
        border-radius: 28px;
        border: 0;
        display: block;
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .content-lable {
      padding: 35px 50px;
      width: 920px;
      height: 620px;
      border-radius: 25px;
      background: #ffffff;
      position: relative;

      .icon-close {
        position: absolute;
        right: 25px;
        top: 25px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .tip1 {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        opacity: 0.8;
      }

      .top-box {
        margin: 10px 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 20px;

        .tip2 {
          font-size: 20px;
          font-weight: 600;
          color: #333333;
        }

        .nums {
          font-size: 16px;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.8);

          .num {
            color: #db9521;
            font-size: 20px;
          }
        }
      }

      .tags-list {
        height: 400px;
        overflow-y: scroll;

        .item-box {
          .categor-name {
            font-size: 16px;
            font-weight: 400;
            color: #999999;
          }

          .label-info-list {
            margin-top: 15px;
            padding: 0 0 0 7px;

            .item {
              margin: 0 15px 15px 0;
              display: inline-block;
              cursor: pointer;

              .custom-button {
                width: 120px;
                height: 43px;
                line-height: 43px;
                background: #f8f8f8;
                border-radius: 22px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                opacity: 0.5;
                text-align: center;
                opacity: 1;

                &[disabled] {
                  background: #e6bf7e;
                  font-size: 14px;
                  font-weight: 600;
                  color: #ffffff;
                }
              }

              // &:nth-child(6n) {
              //   margin-right: 0;
              // }
            }
          }
        }
      }

      .determine {
        margin: 30px auto 0;
        width: 50%;
        height: 55px;
        background: #e6bf7e;
        border-radius: 28px;
        border: 0;
        display: block;
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .content-code {
      padding: 45px 0;
      width: 550px;
      height: 550px;
      position: relative;
      border-radius: 25px;
      background: url(~@/assets/goplay/dialo-bg.png) no-repeat center;
      background-size: 100% 100%;

      .icon-close {
        position: absolute;
        right: 25px;
        top: 25px;
        width: 20px;
        height: 20px;
      }

      .dialog-img1 {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 172px;
      }

      .word {
        margin: 0 auto 24px;
        width: 387px;
        display: block;
      }

      .code {
        margin: 0 auto 25px;
        width: 325px;
        height: 325px;
        background: url(~@/assets/goplay/code.png) no-repeat center;
        background-size: contain;
        border: 15px solid #edc88a;
        box-shadow: 0px 1px 4px 0px rgba(4, 0, 0, 0.15);
        border-radius: 15px;
      }

      .tit {
        margin-top: 25px;
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        text-align: center;
        position: relative;
        z-index: 3;
      }
    }
  }
}
</style>
