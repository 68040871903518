export default function() {
  return [
    /**
      major 后端枚举
      title 模块名
      unOpenTips 未解锁文案
      openTips 解锁后文案
      position 提示区域
      isSelect 是否选中
      isProminent 是否凸显(高亮引导)
      img 房屋图片
      things 装饰物数量
      isWarning 模块有提示
      msgNum 未读数量
     */
    {
      major: 'GRAND_THEATER',
      title: '金城大剧院',
      unOpenTips: '',
      openTips: '人生如戏，在这里体验第二人生吧！',
      position: 'bottom',
      isSelect: false,
      isProminent: false,
      imgs: [
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/GRAND_THEATER.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/GRAND_THEATER_1.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/GRAND_THEATER_2.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/GRAND_THEATER_2.png'
      ],
      things: 1,
      isWarning: false,
      msgNum: 0
    },
    {
      major: 'COFFEE_HOUSE',
      title: '英林咖啡馆',
      unOpenTips: '这家咖啡馆会根据你的性格密码为你分配另一半，从<span data-major="GRAND_THEATER">金城大戏院</span>出来才可以进入哦。',
      openTips: '来咖啡馆享受一下午后时光吧。',
      position: 'bottom',
      isSelect: false,
      isProminent: false,
      imgs: [
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/COFFEE_HOUSE.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/COFFEE_HOUSE_1.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/COFFEE_HOUSE_2.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/COFFEE_HOUSE_2.png'
      ],
      isWarning: false,
      msgNum: 0
    },
    {
      major: 'PUBLIC_SQUARE',
      title: '民园广场',
      unOpenTips: '你将于茫茫人海寻访灵魂伴侣，从<span data-major="GRAND_THEATER">金城大戏院</span>出来才可以进入哦。',
      openTips: '在这里，你将于茫茫人海寻访灵魂伴侣，你做好准备了吗？',
      position: 'top',
      isSelect: false,
      isProminent: false,
      imgs: [
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/PUBLIC_SQUARE.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/PUBLIC_SQUARE_1.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/PUBLIC_SQUARE_2.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/PUBLIC_SQUARE_2.png'
      ],
      isWarning: false,
      msgNum: 0
    },
    {
      major: 'RESTAURANTS',
      title: '同福饭店',
      unOpenTips: '民以食为天，约会怎么离得开吃饭呢？请先去<span data-major="COFFEE_HOUSE">英林咖啡馆</span>完成配对，才可解锁。',
      openTips: '爱情可不能光饮水就饱！美食的浪漫，会让你沉迷于此，而不能自拔！',
      position: 'bottom',
      isSelect: false,
      isProminent: false,
      imgs: [
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/RESTAURANTS.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/RESTAURANTS_1.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/RESTAURANTS_2.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/RESTAURANTS_2.png'
      ],
      things: 2,
      isWarning: false,
      msgNum: 0
    },
    {
      major: 'MOVIE_THEATER',
      title: '环球电影院',
      unOpenTips: '不吃饭怎么有力气看电影呢？请先去<span data-major="RESTAURANTS">同福饭店</span>完成聚餐任务，才可解锁。',
      openTips: '你的心中，是最好的地方，在那里没有人能鄙视我们的爱情！',
      position: 'top',
      isSelect: false,
      isProminent: false,
      imgs: [
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/MOVIE_THEATER.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/MOVIE_THEATER_1.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/MOVIE_THEATER_2.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/MOVIE_THEATER_2.png'
      ],
      things: 1,
      isWarning: false,
      msgNum: 0
    },
    {
      major: 'PUBLIC_GARDEN',
      title: '贝当公园',
      unOpenTips: '逛街约会也很浪漫哦！请先去<span data-major="MOVIE_THEATER">环球电影院</span>完成看电影任务，才可解锁。',
      openTips: '吃饭、看电影、逛街，一个都不能少！散散步说说悄悄话，也是挺浪漫的呢！',
      position: 'bottom',
      isSelect: false,
      isProminent: false,
      imgs: [
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/PUBLIC_GARDEN.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/PUBLIC_GARDEN_1.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/PUBLIC_GARDEN_2.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/PUBLIC_GARDEN_2.png'
      ],
      things: 1,
      isWarning: false,
      msgNum: 0
    },
    {
      major: 'TRAVEL_AGENCY',
      title: '光甫旅行社',
      unOpenTips: '山川岁月的约定是今生最美的相遇，请先去<span data-major="PUBLIC_GARDEN">贝当公园</span>完成逛街任务后解锁！',
      openTips: '人生至少要有两次冲动，奋不顾身的爱情，和说走就走的旅行！赶紧带上心上人，去旅行吧！',
      position: 'top',
      isSelect: false,
      isProminent: false,
      imgs: [
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/TRAVEL_AGENCY.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/TRAVEL_AGENCY_1.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/TRAVEL_AGENCY_2.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/TRAVEL_AGENCY_2.png'
      ],
      isWarning: false,
      msgNum: 0
    },
    {
      major: 'WEDDING_HALL',
      title: '礼堂',
      unOpenTips: '在深入了解对方的基础上再进入婚姻才更美好……需要先去<span data-major="TRAVEL_AGENCY">光甫旅行社</span>完成旅游任务，才可解锁哦。',
      // unOpenTips: '情话说不完的世界总是充满甜蜜，就像这里是潘多拉的魔盒，敬请期待，下期开启',
      openTips: '你的过去我来不及参与， 你的未来我奉陪到底！等你找到ta，就可以在这里举办你们的婚礼。',
      position: 'bottom',
      isSelect: false,
      isProminent: false,
      imgs: [
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/WEDDING_HALL.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/WEDDING_HALL_1.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/WEDDING_HALL_2.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/WEDDING_HALL_2.png'
      ],
      isWarning: false,
      msgNum: 0
    },
    {
      major: 'DIVINATION_HOUSE',
      title: '问心处',
      unOpenTips: '里面有一位神奇的吉普赛女巫，她擅长感情问题，需<span>完成配对</span>后才可以进入。',
      openTips: '女巫：我看你面带桃花，应该正在恋爱中吧？看来你是想要了解关于ta的事',
      position: 'bottom',
      isSelect: false,
      isProminent: false,
      imgs: [
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/DIVINATION_HOUSE.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/DIVINATION_HOUSE_1.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/DIVINATION_HOUSE_2.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/DIVINATION_HOUSE_2.png'
      ],
      things: 1,
      isWarning: false,
      msgNum: 0
    },
    {
      major: 'DEPARTMENT_STORE',
      title: '南方大厦百货',
      unOpenTips: '在这里可以给恋人挑选爱心礼品哦，请先去<span data-major="COFFEE_HOUSE">英林咖啡馆</span>完成配对，才可解锁。',
      openTips: '想要拉近彼此距离，除了增加了解，还可以互赠礼物，想给ta送礼物，就来这里逛逛吧。',
      position: 'bottom',
      isSelect: false,
      isProminent: false,
      imgs: [
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/DEPARTMENT_STORE.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/DEPARTMENT_STORE_1.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/DEPARTMENT_STORE_2.png',
        'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/DEPARTMENT_STORE_2.png'
      ],
      isWarning: false,
      msgNum: 0
    },
    {
      major: 'TEA_HOUSE',
      title: '湖心亭茶楼',
      unOpenTips: '在茶楼约会时聊天、八卦、分享小秘密，能增进感情哦。需要先去<span data-major="COFFEE_HOUSE">英林咖啡馆</span>完成配对，才可解锁。',
      openTips: '在茶楼约会，可以聊天、八卦，也可以更了解彼此哦！',
      position: 'bottom',
      isSelect: false,
      isProminent: false,
      imgs: ['https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/TEA_HOUSE-pc.png', 'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/TEA_HOUSE_1.png', 'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/TEA_HOUSE_2.png', 'https://xld-project-static.oss-cn-hangzhou.aliyuncs.com/xld/theme/ming/house/TEA_HOUSE_2.png'],
      things: 1,
      isWarning: false,
      msgNum: 0
    }
  ];
}
