<template>
  <div class="usActive">
    <Header />
    <div class="about-us">
      <!-- 视频 开始 -->
      <div class="box-top">
        <div class="layout-inner">
          <div class="box-top-left">
            <img src="@/assets/product/about_us/word1.png" class="word1" alt="" />
            <img src="@/assets/product/about_us/word2.png" class="word2" alt="" />
          </div>
          <div class="box-top-right">
            <div class="video-bg">
              <!-- <img v-if="isVideoPlay" src="@/assets/product/about_us/icon-pause.png" class="play" alt="" />
              <img v-else src="@/assets/product/about_us/icon-play.png" class="pause" alt="" /> -->
              <video ref="video" poster="@/assets/product/about_us/video.jpg" src="http://xld-public.oss-cn-beijing.aliyuncs.com/prod/video/xiaoloudong1.mp4" controls muted="muted" loop="loop" :preload="ispreload" class="my-video"></video>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <!-- 视频 结束 -->
      <!-- 探索发现 开始 -->
      <div class="box-explore">
        <div class="layout-inner hidden-xs hidden-sm">
          <div class="explore-box">
            <div v-for="item in explore" :key="item.id" class="explore-item">
              <div class="explore-content">
                <div class="item">
                  <div class="title">
                    <span :style="{ backgroundImage: 'url(' + item.iconsrc + ')' }">{{ item.title }}</span>
                  </div>
                  <div class="info">
                    {{ item.tip1 }} <br />
                    {{ item.tip2 }}
                  </div>
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-explore"><span>探索发现</span></div>
        </div>
        <swiper ref="mySwiper" :options="exploreSwiperOption" class="explore-box visible-sm visible-xs">
          <swiper-slide v-for="(item, index) in explore" :key="index" class="explore-item">
            <div class="explore-content">
              <div class="item">
                <div class="title">
                  <span :style="{ backgroundImage: 'url(' + item.iconsrc + ')' }"> {{ item.title }}</span>
                </div>
                <div class="info">
                  {{ item.tip1 }} <br />
                  {{ item.tip2 }}
                </div>
                <div class="line"></div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="btn-explore visible-sm visible-xs" @click="goExplore"><span>探索发现</span></div>
      </div>
      <!-- 探索发现 结束 -->
      <!-- 场景 开始 -->
      <div class="box-scene">
        <div v-for="(x, xindex) in sceneList" :key="xindex" class="layout-scene layout-scene1" :class="`layout-scene` + (xindex + 1)">
          <div class="layout-inner">
            <div class="scene-left">
              <div class="scene-title">
                <span>{{ x.title }}</span>
              </div>
              <div class="word">
                {{ x.word }}
              </div>
              <div class="character-box">
                <img :src="x.character" class="character1" alt="" />
              </div>
            </div>
            <div class="scene-right">
              <div v-for="(y, yindex) in x.scene" :key="yindex" class="scene-item">
                <div class="content">
                  <div class="title">{{ y.sceneTitle }}</div>
                  <img :src="y.sceneSrc" alt="" />
                  <div class="clear"></div>
                </div>
              </div>
            </div>
            <div class="clear"></div>
          </div>
        </div>
        <div class="layout-scene layout-scene3">
          <div class="layout-inner">
            <div class="scene-title"><span>互动场景</span></div>
            <div class="word">
              为成功配对的用户提供线上约会功能，多个沉浸式剧本给用户有趣的体验，定制的剧本为用户展现不一样的世界
            </div>
            <div class="interaction">
              <swiper ref="mySwiper" :options="swiperOption">
                <swiper-slide v-for="(item, index) in interactionScene" :key="index">
                  <div class="content">
                    <div class="conten-item">
                      <div class="bg" :style="{ backgroundImage: 'url(' + item.sceneSrc + ')' }"></div>
                      <div class="title">{{ item.title }}</div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="swiper-button-prev" @click="prev"></div>
              <div class="swiper-button-next" @click="next"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 场景 结束 -->
      <div class="bg-black"></div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/common/header';
import Footer from '@/components/common/footer';
import 'swiper/swiper-bundle.css';
import Swiper2, { Autoplay } from 'swiper';

Swiper2.use([Autoplay]);
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  components: {
    Header,
    Footer,
    Swiper,
    SwiperSlide
  },
  name: 'location',
  data() {
    return {
      ispreload: 'none',
      isVideoPlay: false,
      explore: [
        {
          id: '1',
          iconsrc: require('@/assets/product/about_us/icon1.png'),
          title: '身份严筛',
          tip1: '严格的实名身份认证体系',
          tip2: '遇见到更真实的他'
        },
        {
          id: '2',
          iconsrc: require('@/assets/product/about_us/icon2.png'),
          title: '算法匹配',
          tip1: '兴趣/经历等多维算法',
          tip2: '精准匹配链接双方志趣'
        },
        {
          id: '3',
          iconsrc: require('@/assets/product/about_us/icon3.png'),
          title: '心理剧本',
          tip1: '一对一沉浸式心理剧本',
          tip2: '让有趣的灵魂终相遇'
        },
        {
          id: '4',
          iconsrc: require('@/assets/product/about_us/icon4.png'),
          title: '情感进阶',
          tip1: '模拟恋爱/试婚全阶段',
          tip2: '寻找与你更契合的人'
        },
        {
          id: '5',
          iconsrc: require('@/assets/product/about_us/icon5.png'),
          title: '多元有趣',
          tip1: '解锁多项趣味互动任务',
          tip2: '让孤单被治愈'
        }
      ],
      sceneList: [
        {
          title: '情景测试',
          word: '沉浸式剧情与科学化心理测验，更加真实直观的了解个人的心理状态与信息，全方位多维度展现个人的信息与形象',
          character: require('@/assets/product/about_us/character1.png'),
          scene: [
            { sceneSrc: require('@/assets/product/about_us/GRAND_THEATER.png'), sceneTitle: '金城大剧院' },
            { sceneSrc: require('@/assets/product/about_us/DIVINATION_HOUSE.png'), sceneTitle: '问心处' },
            { sceneSrc: require('@/assets/product/about_us/TEA_HOUSE.png'), sceneTitle: '湖心亭' }
          ]
        },
        {
          title: '功能场景',
          word: '模拟现实社交情景，实现真实性、沉浸式、生活化的线上交往模式，建立真实连接，发现更多有趣',
          character: require('@/assets/product/about_us/character2.png'),
          scene: [
            { sceneSrc: require('@/assets/product/about_us/COFFEE_HOUSE.png'), sceneTitle: '英林咖啡馆' },
            { sceneSrc: require('@/assets/product/about_us/PUBLIC_SQUARE.png'), sceneTitle: '民园广场' },
            { sceneSrc: require('@/assets/product/about_us/DEPARTMENT_STORE.png'), sceneTitle: '南方百货大厦' }
          ]
        }
      ],
      // 互动场景
      swiperOption: {
        observer: true,
        slidesPerView: 3,
        centeredSlides: true,
        loop: true,
        autoplay: {
          stopOnLastSlide: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      interactionScene: [
        {
          sceneSrc: require('@/assets/product/about_us/RESTAURANTS.png'),
          title: '同福饭店'
        },
        {
          sceneSrc: require('@/assets/product/about_us/MOVIE_THEATER.png'),
          title: '环球电影院'
        },
        {
          sceneSrc: require('@/assets/product/about_us/PUBLIC_GARDEN.png'),
          title: '贝当公园'
        },
        {
          sceneSrc: require('@/assets/product/about_us/TRAVEL_AGENCY.png'),
          title: '光甫旅行社'
        }
      ],
      exploreSwiperOption: {
        slidesPerView: 2.1,
        centeredSlides: true,
        autoplay: true,
        loop: true
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper;
    }
  },
  created() {
    let clinetHeight = document.documentElement.clientHeight;
    let clientWidth = document.documentElement.clientWidth;
    this.styleHeight = 'height:' + clinetHeight + 'px;';
    this.$nextTick(() => {
      if (clientWidth > 752) {
        this.ispreload = 'auto';
        this.videoPlay();
      } else {
        this.ispreload = 'none';
        this.videoPause();
      }
    });
  },
  methods: {
    videoPlay() {
      let video = this.$refs.video;
      this.isVideoPlay = true;
      video.play();
    },
    videoPause() {
      let video = this.$refs.video;
      this.isVideoPlay = false;
      video.pause();
    },
    prev() {
      this.$refs.mySwiper.$swiper.slidePrev();
    },
    next() {
      this.$refs.mySwiper.$swiper.slideNext();
    },
    goExplore() {
      let origin = window.location.origin;
      let http = origin + '/product/#/login';
      window.open(http, '_blank');
    }
  }
};
</script>
<style lang="less" scoped>
.usActive {
  position: relative;
  .us-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    max-width: 68%;
  }
}
@media screen and (max-width: 991px) {
  .usActive {
    .us-bg {
      max-width: 100%;
    }
  }
}
.about-us {
  background: #f3f0ed url(~@/assets/product/about_us/bg2.png) no-repeat bottom center;
  background-size: cover;
  .layout-inner {
    margin: 0 auto;
    width: 1550px;
  }
  .clear {
    clear: both;
  }
  .bg-black {
    width: 100%;
    height: 30px;
    background: #000;
  }
  .box-top {
    padding: 200px 0 130px;
    background: #f7eee2 url(~@/assets/product/about_us/bg1.png) no-repeat bottom center;
    background-size: contain;
    .box-top-left {
      padding-top: 28px;
      float: left;
      img {
        display: block;
      }
      .word1 {
        width: 220px;
      }
      .word2 {
        margin-top: 50px;
        width: 630px;
      }
    }
    .box-top-right {
      margin-right: 6%;
      float: right;
      width: 47%;
      .video-bg {
        position: relative;
        img {
          width: 144px;
          height: 160px;
          opacity: 0.8;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .my-video {
          width: 100%;
        }
      }
    }
  }
  .box-explore {
    margin-top: -90px;
    .explore-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .explore-item {
        margin-right: 35px;
        width: 18%;
        height: 380px;
        &:last-child {
          margin-right: 0;
        }

        .explore-content {
          position: relative;
          width: 100%;
          height: 100%;
          &::after {
            content: '';
            position: absolute;
            right: -10px;
            bottom: -10px;
            width: 100%;
            height: 100%;
            background: #000;
          }
          .item {
            padding: 85px 23px 86px 20px;
            position: relative;
            width: 100%;
            height: 100%;
            background: #ffffff;
            border: 2px solid #2e2a2a;
            z-index: 1;
          }
          .title {
            font-size: 30px;
            font-weight: bold;
            color: #000000;
            height: 30px;
            line-height: 32px;
            text-align: center;

            span {
              padding-left: 50px;
              background: no-repeat left center;
              background-size: contain;
            }
          }
          .info {
            margin-top: 40px;
            font-size: 18px;
            font-weight: 400;
            color: #000000;
            line-height: 50px;
            text-align: center;
          }
          .line {
            margin: 68px auto 0;
            width: 60px;
            height: 2px;
            background: #000000;
            border: 1px solid #000000;
          }
        }
      }
    }
    .btn-explore {
      margin: 110px auto 0;
      position: relative;
      width: 360px;
      height: 100px;
      line-height: 95px;
      span {
        position: relative;
        width: 100%;
        height: 100%;
        background: #e6c692;
        border: 2px solid #2e2a2a;
        border-radius: 10px;
        font-size: 48px;
        color: #000000;
        text-align: center;
        display: block;
        z-index: 2;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: #000000;
        z-index: 1;
      }
    }
  }
  .box-scene {
    margin-top: 170px;
    .scene-title {
      position: relative;
      font-size: 48px;
      color: #000000;
      line-height: 44px;
      span {
        position: relative;
        z-index: 1;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 200px;
        height: 27px;
        background: #e6c692;
        border-radius: 10px;
      }
    }
    .layout-scene {
      .scene-left {
        width: 480px;
        float: left;
        .word {
          margin-top: 50px;
          font-size: 30px;
          color: #000000;
          line-height: 72px;
        }
        .character1 {
          margin-top: 160px;
        }
      }
      .scene-right {
        width: 924px;
        float: right;
        .scene-item {
          margin-bottom: 40px;
          position: relative;
          width: 100%;
          height: 180px;
          &::after {
            content: '';
            position: absolute;
            bottom: -13px;
            right: -9px;
            width: 100%;
            height: 100%;
            background: #f2e0c7;
            border: 2px solid #2e2a2a;
            border-radius: 10px;
          }
          .content {
            padding-right: 55px;
            position: relative;
            width: 100%;
            height: 100%;
            border: 2px solid #2e2a2a;
            border-radius: 10px;
            z-index: 1;
            background: #ffffff;
            .title {
              padding-top: 120px;
              margin-left: 55px;
              font-size: 32px;
              color: #333333;
              line-height: 30px;
              float: left;
            }
            img {
              width: 270px;
              display: block;
              float: right;
            }
          }
        }
      }
    }
    .layout-scene1 {
      .scene-right {
        .scene-item {
          &:nth-child(1) {
            .content {
              img {
                margin-top: -30px;
              }
            }
          }
          &:nth-child(2) {
            &::after {
              background: #ffbaaa;
            }
            .content {
              img {
                width: 240px;
                margin-top: -20px;
              }
            }
          }
          &:nth-child(3) {
            &::after {
              background: #bce4d3;
            }
            .content {
              img {
                margin-top: 11px;
              }
            }
          }
        }
      }
    }
    .layout-scene2 {
      margin-top: 150px;
      .scene-title {
        text-align: right;
        &::after {
          right: 0;
          left: auto;
        }
      }
      .character-box {
        width: 100%;
        text-align: right;
      }
      .scene-left {
        float: right;
      }
      .scene-right {
        float: left;
        .scene-item {
          .content {
            img {
              float: left;
            }
            .title {
              float: right;
            }
          }
          &:nth-child(1) {
            .content {
              img {
                margin-left: 83px;
                margin-top: -30px;
                width: 180px;
              }
            }
          }
          &:nth-child(2) {
            &::after {
              background: #ffbaaa;
            }
            .content {
              img {
                margin-left: 35px;
                margin-top: 8px;
                width: 280px;
              }
            }
          }
          &:nth-child(3) {
            &::after {
              background: #bce4d3;
            }
            .content {
              img {
                margin-left: 64px;
                margin-top: -20px;
                width: 230px;
              }
            }
          }
        }
      }
    }
    .layout-scene3 {
      margin: 134px 0 198px;
      .scene-title {
        text-align: center;
        &::after {
          right: 0;
          margin: auto;
        }
      }
      .word {
        margin: 43px auto 0;
        width: 870px;
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        color: #000000;
        line-height: 72px;
      }
      .interaction {
        position: relative;
        .swiper-container {
          padding: 90px 0 150px;
          .swiper-wrapper {
            .swiper-slide {
              padding: 0 50px;
              width: 560px;
              height: 460px;
              .content {
                position: relative;
                width: 100%;
                height: 100%;
                transition: all 0.3s;
                &::after {
                  content: '';
                  position: absolute;
                  bottom: -13px;
                  right: -9px;
                  width: 100%;
                  height: 100%;
                  background: #f2e0c7;
                  border: 2px solid #2e2a2a;
                }
                .conten-item {
                  position: relative;
                  z-index: 1;
                  width: 100%;
                  height: 100%;
                  background: #fff;
                  border: 2px solid #2e2a2a;
                }
                .bg {
                  margin: 30px auto 45px;
                  width: 360px;
                  height: 320px;
                  background: no-repeat center;
                  background-size: contain;
                }
                .title {
                  font-size: 32px;
                  color: #333333;
                  height: 30px;
                  line-height: 30px;
                  text-align: center;
                }
              }
            }
            .swiper-slide-active,
            .swiper-slide-duplicate-active {
              transform: translateY(-36px);
            }
          }
        }
        .swiper-button-prev,
        .swiper-button-next {
          top: auto;
          bottom: 0;
          width: 80px;
          height: 70px;
          z-index: 5;
          &::after {
            display: none;
          }
        }
        .swiper-button-prev {
          left: 36%;
          background: url(~@/assets/product/about_us/icon_prev.png) no-repeat center;
          background-size: contain;
        }
        .swiper-button-next {
          right: 36%;
          background: url(~@/assets/product/about_us/icon_next.png) no-repeat center;
          background-size: contain;
        }
      }
    }
  }
}
@media screen and (max-width: 1680px) {
  .about-us {
    .layout-inner {
      margin: 0 auto;
      width: 1200px;
    }
    .box-top {
      padding-top: 180px;
      .box-top-left {
        .word1 {
          width: 170px;
        }
        .word2 {
          margin-top: 40px;
          width: 480px;
        }
      }
    }
    .box-explore {
      margin-top: -100px;
      .explore-box {
        .explore-item {
          margin-right: 28px;
          width: 220px;
          height: 300px;

          .explore-content {
            &:after {
              right: -8px;
              bottom: -8px;
            }
            .item {
              padding: 56px 13px 0 19px;
            }
            .title {
              font-size: 24px;
              span {
                padding-left: 35px;
                background-size: auto 25px;
              }
            }
            .info {
              margin-top: 30px;
              font-size: 16px;
              line-height: 40px;
            }
            .line {
              margin: 50px auto 0;
            }
          }
        }
      }
      .btn-explore {
        margin: 100px auto 0;
        width: 350px;
        height: 90px;
        line-height: 90px;
        span {
          font-size: 38px;
        }
      }
    }
    .box-scene {
      .scene-title {
        font-size: 1.8em;
        line-height: 1.4em;
        height: 1.4em;
        &::after {
          width: 160px;
          height: 21px;
        }
      }
      .layout-scene {
        .scene-left {
          width: 27%;
          .word {
            margin-top: 2em;
            font-size: 1em;
            line-height: 2em;
          }
          .character1 {
            margin-top: 130px;
          }
        }
        .scene-right {
          width: 59.6%;
          .scene-item {
            height: 150px;
            .content {
              .title {
                padding-top: 90px;
                font-size: 28px;
              }
              img {
                width: 240px;
              }
            }
          }
        }
      }
      .layout-scene1 {
        .scene-right {
          .scene-item {
            &:nth-child(2) {
              .content {
                img {
                  width: 210px;
                }
              }
            }
            &:nth-child(3) {
              .content {
                img {
                  margin-top: 6px;
                }
              }
            }
          }
        }
      }
      .layout-scene2 {
        .scene-right {
          .scene-item {
            &:nth-child(1) {
              .content {
                img {
                  margin-left: 53px;
                  width: 150px;
                }
              }
            }
            &:nth-child(2) {
              .content {
                img {
                  margin-top: 16px;
                  width: 210px;
                }
              }
            }
            &:nth-child(3) {
              .content {
                img {
                  margin-left: 35px;
                  margin-top: -22px;
                  width: 200px;
                }
              }
            }
          }
        }
      }
      .layout-scene3 {
        margin: 107px 0 134px;
        .word {
          margin-top: 2em;
          width: 56%;
          font-size: 1.1em;
          line-height: 2em;
        }
        .interaction {
          .swiper-container {
            .swiper-wrapper {
              .swiper-slide {
                padding: 0 30px;
                height: 368px;
                .content {
                  .bg {
                    width: 240px;
                    height: 208px;
                  }
                  .title {
                    font-size: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .visible-xs {
    display: none !important;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .hidden-sm {
    display: none !important;
  }
}
@media screen and (max-width: 991px) {
  .about-us {
    .layout-inner {
      padding: 0 0.2rem;
      width: 100%;
    }
    .bg-black {
      height: 0.1rem;
    }
    .box-top {
      padding: 0.4rem 0 0.8rem;
      background: #f7eee2 url(~@/assets/product/about_us/bg1.jpg) no-repeat bottom center;
      background-size: cover;
      .layout-inner {
        .box-top-left {
          padding-left: 0.2rem;
          padding-top: 0;
          float: none;
          .word1 {
            width: 1.18rem;
          }
          .word2 {
            margin: 0.2rem 0 0.4rem;
            width: 2.82rem;
            max-width: 100%;
          }
        }
      }
      .box-top-right {
        margin-right: 0;
        float: none;
        width: 100%;
        .video-bg {
          position: relative;
          width: 100%;
          height: 2.2rem;
          video {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .box-explore {
      margin-top: -0.4rem;
      .explore-box {
        padding-bottom: 0.4rem;
        .explore-item {
          margin: 0;
          width: 100%;
          height: auto;
          transform: scale(0.9);
          transition: all 0.3s;
          .explore-content {
            &::after {
              right: -0.03rem;
              bottom: -0.03rem;
            }
            .item {
              padding: 0.2rem 0.1rem 0.2rem;
            }
            .title {
              font-size: 0.16rem;
              height: 0.2rem;
              line-height: 0.2rem;
              span {
                padding-left: 0.25rem;
                background: no-repeat left center;
                background-size: auto 0.2rem;
              }
            }
            .info {
              margin-top: 0.15rem;
              font-size: 0.12rem;
              line-height: 0.3rem;
            }
            .line {
              margin: 0.2rem auto 0;
              width: 0.28rem;
              height: 0.01rem;
              background: #000;
              border: 0;
            }
          }
        }
        .swiper-slide-active,
        .swiper-slide-duplicate-active {
          transform: scale(1);
        }
      }
      .btn-explore {
        margin: 0 auto;
        width: 1.12rem;
        height: 0.44rem;
        line-height: 0.44rem;
        span {
          font-size: 0.18rem;
          border-radius: 0.05rem;
          border: 1px solid #2e2a2a;
          font-weight: bold;
        }
        &::after {
          bottom: -0.03rem;
          border-radius: 0.05rem;
        }
      }
    }
    .box-scene {
      margin-top: 0.3rem;
      .scene-title {
        font-size: 0.18rem;
        line-height: 0.22rem;
        height: 0.22rem;
        font-weight: bold;
        &::after {
          border-radius: 0.1rem;
          width: 0.74rem;
          height: 0.08rem;
        }
      }
      .layout-scene {
        .scene-left {
          padding-bottom: 0.8rem;
          float: none;
          width: 100%;
          position: relative;
          .word {
            margin: 0.12rem 0 0;
            width: 83%;
            font-size: 0.14rem;
            line-height: 0.24rem;
          }
          .character-box {
            position: absolute;
            bottom: 0;
            .character1 {
              margin-top: 0;
              width: 0.56rem;
            }
          }
        }
        .scene-right {
          float: none;
          width: 100%;
          .scene-item {
            height: auto;
            margin-bottom: 0.45rem;
            &:after {
              bottom: -0.05rem;
              right: -0.03rem;
              height: 88%;
              border-radius: 0.05rem;
              border: 1px solid #333333;
            }
            .content {
              padding: 0 0.15rem;
              border-radius: 0.05rem;
              border: 1px solid #333333;
              .title {
                padding-top: 0.4rem;
                margin-left: 0;
                font-size: 0.14rem;
              }
              img {
                width: 1.2rem;
              }
            }
          }
        }
      }
      .layout-scene1 {
        .scene-left {
          .character-box {
            right: 0;
            bottom: 0.4rem;
          }
        }
        .scene-right {
          .scene-item {
            .content {
              .title {
                float: right;
              }
              img {
                float: left;
              }
            }

            &:nth-child(1) {
              .content {
                img {
                  margin-top: -0.2rem;
                  width: 1.3rem;
                }
              }
            }
            &:nth-child(2) {
              .content {
                img {
                  margin-top: -0.2rem;
                  width: 1.2rem;
                }
              }
            }
            &:nth-child(3) {
              margin-bottom: 0;
              .content {
                img {
                  margin-top: -0.17rem;
                  width: 1.4rem;
                }
              }
            }
          }
        }
      }
      .layout-scene2 {
        margin-top: 0.4rem;
        .scene-left {
          .word {
            margin-left: 0.7rem;
            text-align: right;
          }
          .character-box {
            left: 0;
            bottom: 0.4rem;
            width: 0.56rem;
          }
        }
        .scene-right {
          .scene-item {
            .content {
              .title {
                float: left;
              }
              img {
                float: right;
              }
            }
            &:nth-child(1) {
              .title {
                padding-top: 0.5rem;
              }
              .content {
                img {
                  margin-top: -0.3rem;
                  width: 1rem;
                }
              }
            }
            &:nth-child(2) {
              .content {
                img {
                  margin-left: 0.1rem;
                  margin-top: -0.1rem;
                  width: 1.4rem;
                }
              }
            }
            &:nth-child(3) {
              margin-bottom: 0;
              .title {
                padding-top: 0.5rem;
              }
              .content {
                img {
                  margin-right: 0.02rem;
                  margin-top: -0.2rem;
                  width: 1.2rem;
                }
              }
            }
          }
        }
      }
      .layout-scene3 {
        margin: 0.4rem 0 0.23rem;
        .layout-inner {
          .word {
            margin-top: 0.15rem;
            width: 86%;
            font-weight: normal;
            font-size: 0.14rem;
            line-height: 0.24rem;
          }
          .interaction {
            .swiper-container {
              padding: 0.4rem 0 0.77rem;
              .swiper-wrapper {
                .swiper-slide {
                  padding: 0 0.07rem;
                  height: auto;
                  transition: all 0.3s;
                  .content {
                    &::after {
                      bottom: -0.04rem;
                      right: -0.04rem;
                      border: 1px solid #2e2a2a;
                    }
                    .conten-item {
                      padding-bottom: 0.08rem;
                      border: 1px solid #2e2a2a;
                    }

                    .bg {
                      margin: 0.08rem auto 0.05rem;
                      width: 0.9rem;
                      height: 0.7rem;
                    }
                    .title {
                      font-size: 0.12rem;
                      height: 0.17rem;
                      line-height: 0.17rem;
                    }
                  }
                }
                .swiper-slide-active,
                .swiper-slide-duplicate-active {
                  transform: translateY(-0.1rem);
                }
              }
            }
            .swiper-button-prev,
            .swiper-button-next {
              bottom: 0;
              width: 0.28rem;
              height: 0.24rem;
            }
            .swiper-button-prev {
              left: 35%;
            }
            .swiper-button-next {
              right: 35%;
            }
          }
        }
      }
    }
  }
}
</style>
